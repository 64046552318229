import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from "../../../utils/Translations";

class TileNewVideosCount extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		const text = this.props.newVideosCount + " " + (this.props.newVideosCount === 1 ? Translations.get('ActorProfileNewVideo') : Translations.get('VideoGridNewestTitle'));

		return <span className={'badge -newVideoCount'}>{text}</span>;
	}
}

TileNewVideosCount.propTypes = {
	show:           PropTypes.bool.isRequired,
	newVideosCount: PropTypes.number,
};

TileNewVideosCount.defaultProps = {
	show: false,
};

export default TileNewVideosCount;