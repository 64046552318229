import React            from 'react';
import PropTypes        from 'prop-types';

const CLASS_NAME = 'badge--highlight tile-badge tile-badge-star';

class TileBadgeStar extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME}>Star</span>;
	}
}

TileBadgeStar.propTypes = {
	show: PropTypes.bool.isRequired,
};

TileBadgeStar.defaultProps = {
	show: false,
};

export default TileBadgeStar;
