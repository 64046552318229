import React           from 'react';
import PropTypes       from 'prop-types';
import Translations    from '../../utils/Translations';
import ActorPin        from '../Favorite/Actor/Pin';
import VideochatButton from '../SimpleElements/VideochatButton';
import Flux            from '../../flux/Flux';
import VideochatButtonWrapper from '../SimpleElements/VideochatButtonWrapper';

class ActorPlateExtended extends React.PureComponent {

    render() {
        return (
                <div className="row context-box--media-box__plate -actor-plate-extended">
                    <div className="col-xs-6">
                        <ul className="h-list--horizontal actor-name">
                            <li className="context-box--media-box__list__item actor-plate__actor-name -size-medium"
                                title={this.props.name}
                            ><h3 className="actor-plate__actor-name -size-medium">{this.props.name}</h3></li>
                            <li className="context-box--media-box__list__item actor-age -size-medium">({this.props.age})</li>
                            {Flux.Guest.isLoggedIn() && <li className="context-box--media-box__list__item">
							<span className="actorFavorite">
								<ActorPin actorId={this.props.actorId}
                                          initialIsFavoriteOfGuest={this.props.isModelGuestFavorite}
                                          fontSize="-size-sm"
                                />
							</span>
                            </li>}
                        </ul>
                        <ul className="h-list--horizontal actor-plate__actor-languages">
                            {this.props.spokenLanguages.map(lang =>
                                    <li key={'lang-' + lang} className="context-box--media-box__list__item">
                                        <span className={"badge-language -lang-" + lang}> </span>
                                    </li>
                            )}
                        </ul>
                    </div>
                    <div className="col-xs-6">
                        <div className="btn__videochat">
                            <VideochatButtonWrapper type={Flux.Constants.VideochatButtonTypes.actorPlateExtendedVideochat} actorId={this.props.actorId} />
                        </div>
                    </div>
                </div>
        );
    }
}

ActorPlateExtended.propTypes = {
    actorId:                 PropTypes.number.isRequired,
    name:                    PropTypes.string.isRequired,
    age:                     PropTypes.number.isRequired,
    spokenLanguages:         PropTypes.array.isRequired,
    isModelGuestFavorite:    PropTypes.bool.isRequired,
};

export default ActorPlateExtended;
