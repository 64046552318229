import React            from 'react';
import PropTypes        from 'prop-types';
import TopAmateurRibbon from './TopAmateurRibbon';
import FreeShowRibbon   from './FreeShowRibbon';
import FathersDayRibbon from './FathersDayRibbon';
import Tile             from './../Tile';

class TileRibbonsList extends React.PureComponent {
	render() {
		const data = this.props.data;

		const showTop     = data.type === Tile.type.HIGHLIGHT_ACTOR_GRID_TILE && data.isTopAmateur;
		const showFree    = data.type === Tile.type.HIGHLIGHT_ACTOR_GRID_TILE && data.todayFreeShow;
		const showFathers = data.showFathersDayRibbon;

		// hide the whole container if no ribbons shown
		if (!showFathers && !showFree && !showTop) {
			return null;
		}

		return (
			<div className={'ribbon-container' + this.props.badgeContainerAdditionalClassName}>
				<TopAmateurRibbon show={showTop} />
				<FreeShowRibbon show={showFree} showStart={data.todayFreeShowStart} />
				<FathersDayRibbon
					useTileWithSecondary={this.props.useTileWithSecondary}
					pictureLayout={data.pictureLayout}
					show={showFathers}
				/>
			</div>
		);
	}
}

TileRibbonsList.propTypes = {
	data:                              PropTypes.object.isRequired,
	useTileWithSecondary:              PropTypes.bool,
	badgeContainerAdditionalClassName: PropTypes.string,
};

TileRibbonsList.defaultProps = {
	useTileWithSecondary:              false,
	badgeContainerAdditionalClassName: '',
};

export default TileRibbonsList;
