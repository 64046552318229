import React                  from 'react';
import PropTypes              from 'prop-types';
import Translations from '../../../utils/Translations';

const CLASS_NAME = 'badge--videocall tile-badge tile-badge-live -non-hover-overlay';

class TileBadgeVideocall extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME + ' ' + this.props.className}>{Translations.get('FavoriteLastOnlineTextOnline')}</span>;
	}
}

TileBadgeVideocall.propTypes    = {
	show:      PropTypes.bool,
	className: PropTypes.string,
};
TileBadgeVideocall.defaultProps = {
	className: '',
};


export default TileBadgeVideocall;
