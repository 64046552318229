import React                 from 'react';
import PropTypes             from 'prop-types';
import Flux                  from '../../flux/Flux';
import Translations          from './../../utils/Translations';
import {VXPay}               from '../../utils/VXPay';
import PictureBoxes          from './PictureBoxes';
import ReloadHelper          from '../../utils/ReloadHelper';
import AvsPopUpTile          from './AvsPopUpTile';
import DildocontrolOverlay   from "./DildocontrolOverlay";
import reactVisibilitySensor from "react-visibility-sensor";
import Pin                   from "../Favorite/Actor/Pin";
import VideochatButtonWrapper from '../SimpleElements/VideochatButtonWrapper';

let runningTransitions = [];
let VisibilitySensor   = null;

class ActorTile extends React.Component {

	static handleMouseEnterVideoButton(e) {
		const btn = document.getElementById(e.currentTarget.id).querySelector(".-icon-webcam-single-full.-size-actortiles");
		if (!btn) {
			return;
		}
		window.setTimeout(function() {
			btn.disabled = true;
		}, 0);
		window.setTimeout(function() {
			btn.disabled = false;
		}, 3200);
	}

	constructor(props) {
		super(props);

		this.state = {
			isTileInView: false,
		};

		this.pictureBoxes = null;

		this.openMessengerFn      = this.openMessengerFn.bind(this);
		this.handleActorTileClick = this.handleActorTileClick.bind(this);
		this.updateTileInView     = this.updateTileInView.bind(this);
	}

	// this.props.item.type: Actor, FavoriteActor, GuestChat
	openMessengerFn(e) {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(this.props.item.actorId, true);
		} else {
			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  this.props.item.actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				});
			} else {
				VXPay.openSignupOrLogin();
			}
		}
		if ((typeof e === 'object') && e.cancelable) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (typeof this.props.onOpenMessengerClick === 'function') {
			this.props.onOpenMessengerClick();
		}

		return false;
	}

	handleActorTileClick(e) {
		const actorData = this.props.item;

		// store data of current tile to be used as preliminary data on actor profile
		actorData.previewPicture = this.pictureBoxes ? this.pictureBoxes.getCurrentImageSource(0) : null;
		Flux.Actor.setPreliminaryData(actorData);

		if (!this.props.isTall &&
			this.props.animateTouch === true &&
			e.target.nodeName === 'IMG') {

			if (runningTransitions.indexOf(actorData.actorId) === -1) {
				runningTransitions.push(actorData.actorId);
				window.setTimeout(() => {
					const idx = runningTransitions.indexOf(actorData.actorId);
					if (idx > -1) {
						runningTransitions = runningTransitions.splice(idx, 1);
					}
				}, 2000);

				if ((typeof e === 'object') && e.cancelable) {
					e.stopPropagation();
					e.preventDefault();
				}
			} else {
				this.props.onClickFn(e, actorData);
			}
		} else {
			this.props.onClickFn(e, actorData);
		}

		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.item.targetUrl, e);
		}
	}

	updateTileInView(isVisible) {
		if (this.state.isTileInView !== isVisible) {
			this.setState({
				isTileInView: isVisible,
			});
		}
	}

	render() {
		const item                    = this.props.item;
		let additionalImageClassName  = '';
		let additionalFigureClassName = '';
		let contentBoxClassName       = 'content-box';

		// for mobile: only use the first image to improve performance
		if (this.props.isMobile && !this.props.autoAnimateInView) {
			item.previewPictureUrls.length = 1;
		}

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		if (this.props.animateTile === true && item.previewPictureUrls.length > 1) {
			additionalImageClassName = ' -animation-start';
		}

		if (this.state.isTileInView === true && item.previewPictureUrls.length > 1) {
			additionalImageClassName  = ' -animation-start';
			additionalFigureClassName = ' -show-badges';
		}

		if (item.previewPictureUrls.length <= 1) {
			additionalFigureClassName = ' -no-animation';
		}

		if (this.props.isTall && this.props.isMobile) {
			additionalFigureClassName += ' -height-tall';
		}

		const pictureBoxes = <PictureBoxes pictures={item.previewPictureUrls}
		                                   useIndexForClassName={true}
		                                   outerClassName={'actor-image' + additionalImageClassName}
		                                   ref={(pictureBoxesRef) => {
			                                   this.pictureBoxes = pictureBoxesRef;
		                                   }}
		                                   usePlaceholder={true}
		                                   setMaxWidth={item.usesAvatarAsPreviewPicture}
										   lazyImageLoading={this.props.lazyImageLoading}
		/>;

		let actorTile = <div className={this.props.className}>
			<div className={contentBoxClassName}>
				{Flux.Guest.isLoggedIn() && !item.isFsk16ChatAvailable && !Flux.Guest.hasAvs() && Flux.Guest.isPayingCustomer() && !this.props.isMobile && !this.props.isHighlight &&
				<AvsPopUpTile isPayingCustomer={Flux.Guest.isPayingCustomer()} actorName={item.name} />
				}
				<a href={item.targetUrl}
				   className={"link -color-standard-white h-disp-block actor-tile" + additionalFigureClassName}
				   id={"actor-tile-" + this.props.tileIndex}
				   onMouseEnter={ActorTile.handleMouseEnterVideoButton}
				   onClick={this.handleActorTileClick}
				>
					<div>
						{pictureBoxes}
						{item.isDildocontrolAvailable && item.isOnline && (!Flux.Guest.isLoggedIn() || Flux.Guest.hasAvs()) &&
						<DildocontrolOverlay />}
					</div>
					{this.props.showContactLayer && <div className="actorcontact__container">
						<div className="actorcontact">
							<div className="actorcontact__text">
								<span className="actorcontact__text-actorname">{item.name}</span>
								<Pin actorId={item.actorId} initialIsFavoriteOfGuest={item.isModelGuestFavorite} fontSize="-size-xs" />
							</div>
							{this.props.showButtonProfile &&
							<button className="btn--icon-detailed -icon-user-full -size-sm -click-material -size-actortiles">
								<span className="ink animate" />
								{Translations.get('ActorGridProfileText')}
								<span className="btn--icon-detailed__teaser">{Translations.get('ActorGridLearnMoreText')}</span>
							</button>}
							{item.isOnline && <VideochatButtonWrapper actorId={item.actorId} type={Flux.Constants.VideochatButtonTypes.actorTile} />}
							{(!item.isOnline || this.props.showButtonMessenger) &&
							<button className="btn--icon-detailed -btn-color- -icon-chat-text-full -click-material -size-sm -size-actortiles"
							        onClick={this.openMessengerFn}
							>
								<span className="ink animate" />
								{Translations.get('ActorGridMessageText')}
								<span className="btn--icon-detailed__teaser">{Translations.get('ActorGridWriteFreeText')}</span>
							</button>}
						</div>
					</div>}
					{this.props.children}
				</a>
			</div>
		</div>;

		if (this.props.autoAnimateInView && item.previewPictureUrls.length > 1) {
			if (!VisibilitySensor) {
				VisibilitySensor = reactVisibilitySensor;
			}

			actorTile = <VisibilitySensor onChange={this.updateTileInView} intervalDelay={750} delayedCall={true}>
				{actorTile}
			</VisibilitySensor>;
		}

		return actorTile;
	}
}

ActorTile.propTypes = {
	isTall:                  PropTypes.bool,
	item:                    PropTypes.object.isRequired,
	children:                PropTypes.node,
	className:               PropTypes.string,
	tileIndex:               PropTypes.number.isRequired,
	animateTile:             PropTypes.bool,
	animateTouch:            PropTypes.bool,
	autoAnimateInView:       PropTypes.bool,
	showButtonMessenger:     PropTypes.bool,
	showButtonProfile:       PropTypes.bool,
	showContactLayer:        PropTypes.bool,
	isHighlight:             PropTypes.bool,
	isMobile:                PropTypes.bool,
	onClickFn:               PropTypes.func,
	onOpenMessengerClick:    PropTypes.func,
	contentBoxClassModifier: PropTypes.string,
	usePjax:                 PropTypes.bool,
	lazyImageLoading:        PropTypes.bool,
	headlineType:            PropTypes.oneOf(Object.values(Flux.Constants.Headlines)),
	gaString:                PropTypes.string,
	isOnlineMobileVideocall: PropTypes.bool,
};

ActorTile.defaultProps = {
	className:               'col-lg-3 col-xs-6',
	animateTile:             false,
	animateTouch:            false,
	autoAnimateInView:       false,
	contentBoxClassModifier: '-padding-equal-smooth',
	showContactLayer:        true,
	showButtonProfile:       false,
	showButtonMessenger:     false,
	isHighlight:             false,
	isMobile:                false,
	onClickFn:               () => {
	},      // params: e, actorData
	usePjax:                 false,
	lazyImageLoading:        false,
	gaString:                "",
	isOnlineMobileVideocall: false,
};

export default ActorTile;
