import React               from 'react';
import PropTypes           from 'prop-types';
import PictureBoxes        from './PictureBoxes';
import Flux                from '../../flux/Flux';
import PreviewPictures     from '../Gallery/PreviewPictures';
import PjaxWrapper         from "../../utils/PjaxWrapper";
import DildocontrolOverlay from "./DildocontrolOverlay";

const LAYOUT_1X1 = '1x1';
const LAYOUT_4X4 = '4x4';

const VIDEO_ALBUM_GRID_TILE = 'VIDEO_ALBUM_GRID_TILE';
const PHOTO_ALBUM_GRID_TILE = 'PHOTO_ALBUM_GRID_TILE';

class DefaultTile extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			videoPreviewPictures: null,
		};

		this.onVideoTileDataChanges = this.onVideoTileDataChanges.bind(this);
		this.onClickFn              = this.onClickFn.bind(this);
		this.onHover                = this.onHover.bind(this);
	}

	UNSAFE_componentWillMount() {
		if ((this.props.item.type === VIDEO_ALBUM_GRID_TILE || (this.props.item.type === PHOTO_ALBUM_GRID_TILE && this.props.item.showPreviewPicturesOnHover)) && !this.props.isMobile) {
			Flux.TileGrid.addVideoTileDataChangeListener(this.onVideoTileDataChanges);
		}
	}

	componentWillUnmount() {
		if ((this.props.item.type === VIDEO_ALBUM_GRID_TILE || (this.props.item.type === PHOTO_ALBUM_GRID_TILE && this.props.item.showPreviewPicturesOnHover)) && !this.props.isMobile) {
			Flux.TileGrid.removeVideoTileDataChangeListener(this.onVideoTileDataChanges);
		}
	}

	onVideoTileDataChanges(albumId) {
		if (albumId === this.props.item.id) {
			this.setState(
				{
					videoPreviewPictures: Flux.TileGrid.getPictureData(this.props.item.id),
				}
			);
		}
	}

	onClickFn(e) {
        this.props.onClickFn(e, this.props.item, this.props.tileIndex);
        if (this.props.item.type === PHOTO_ALBUM_GRID_TILE || this.props.item.type === Flux.Constants.TileGrid.PINBOARD_PHOTOS) {
            e.preventDefault();
            return;
        }
		if (this.props.usePjax && !this.props.item.isLinkOpeningGallery && this.props.item.targetUrl !== window.location.pathname && (this.props.item.type !== Flux.Constants.TileGrid.PINBOARD_PHOTOS && !this.props.isMobile)) {
			if (this.props.ignorePjaxScroll) {
				PjaxWrapper.setIgnoreNextPjaxScroll();
			}
		}
	}

	onHover() {
		if (!this.props.isMobile && (this.props.item.type === VIDEO_ALBUM_GRID_TILE || (this.props.item.type === PHOTO_ALBUM_GRID_TILE && this.props.item.showPreviewPicturesOnHover))) {
			Flux.TileGrid.loadVideoAlbumPictures(this.props.item.id);
		}
	}

	render() {
		let ratioClass, colClass, responsiveMultiplier, boxRatio, previewPictureUrls;
		let secondaryBoxRatio, secondaryRatioClass, secondaryColClass, secondaryResponsiveMultiplier, secondaryPictureBoxes,
		    secondaryPreviewPictureUrls;

		const tileClassName = this.props.className;
		const item          = this.props.item;
		let useRatio        = this.props.useRatio;

		let contentBoxClassName = 'context-box--media-box h-over-hidden clearfix' + (this.props.useTileWithSecondary ? ' -extended-plate' : '');

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		if (this.props.useTileWithSecondary) {
			useRatio = true;

			colClass             = '-split1';
			responsiveMultiplier = 2;
			previewPictureUrls   = item.previewPictureUrls.slice(0, 1);
			if (item.previewPictureUrls.length === 1) {
				boxRatio   = this.props.isMobile ? 100 / 75 : 100 / 53;
				ratioClass = this.props.isMobile ? '-ratio100_75' : '-ratio100_53';
			} else {
				boxRatio   = this.props.isMobile ? 1 : 100 / 67;
				ratioClass = this.props.isMobile ? '-ratio75_75' : '-ratio100_67';

				secondaryBoxRatio             = this.props.isMobile ? 1 : 13 / 11;
				secondaryRatioClass           = this.props.isMobile ? '-ratio25_25' : '-ratio13_11';
				secondaryColClass             = '-split2';
				secondaryResponsiveMultiplier = 1;
				secondaryPreviewPictureUrls   = item.previewPictureUrls.slice(1, 4);

				// fill up preview pictures to avoid broken layout
				while (secondaryPreviewPictureUrls.length < 3) {
					secondaryPreviewPictureUrls.push([{
						format:     'w0',
						height:     0,
						id:         0,
						isPortrait: false,
						src:        '/assets/img/transparent.gif',
						width:      0,
					}]);
				}
			}
		} else {
			previewPictureUrls = item.previewPictureUrls;

			if (this.props.hasMultipleItems) {
				while (previewPictureUrls.length < 3) {
					previewPictureUrls.push([{
						format:     'w0',
						height:     0,
						id:         0,
						isPortrait: false,
						src:        '/assets/img/transparent.gif',
						width:      0,
					}]);
				}
			}

			switch (item.pictureLayout) {
				case LAYOUT_4X4:
					boxRatio             = 4 / 3;
					ratioClass           = '-ratio4_3';
					colClass             = '-col-2';
					responsiveMultiplier = 3;
					break;
				case LAYOUT_1X1:
				default:
					boxRatio             = 4 / 3;
					ratioClass           = '-ratio4_3';
					colClass             = '';
					responsiveMultiplier = 2;
			}
		}

		if (this.props.isMobile) {
			responsiveMultiplier = 1;
		}

		const outerClassName = 'context-box--media-box__picture' + (useRatio ? ' box--fixed-ratio' : '');
		const innerClassName = useRatio ? 'box--fixed-ratio__content' : '';

		let pictureBoxes;
		if (!this.state.videoPreviewPictures || this.state.videoPreviewPictures['pictures'].length === 0) {
			pictureBoxes = <PictureBoxes
				pictures={previewPictureUrls}
				useIndexForKey={true}
				outerClassName={outerClassName + ' ' + colClass + ' ' + ratioClass}
				innerClassName={innerClassName}
				responsiveMultiplier={responsiveMultiplier}
				boxRatio={boxRatio}
				useTileWithSecondary={this.props.useTileWithSecondary}
				isPhotoAlbumGridTile={this.props.item.type === PHOTO_ALBUM_GRID_TILE}
				isMobile={this.props.isMobile}
				lazyImageLoading={this.props.lazyImageLoading}
			               />;
		} else {
			pictureBoxes = <div className={outerClassName + ' ' + colClass + ' ' + ratioClass}>
				<PreviewPictures
					className="-transparent"
					displayStyle={'block'}
					previewPictureUrls={this.state.videoPreviewPictures['pictures']}
					showInVideoTile={true}
					lazyImageLoading={this.props.lazyImageLoading}
				/>
			</div>;
		}
		if (secondaryColClass) {
			secondaryPictureBoxes = <PictureBoxes
				pictures={secondaryPreviewPictureUrls}
				useIndexForKey={true}
				outerClassName={outerClassName + ' ' + secondaryColClass + ' ' + secondaryRatioClass}
				innerClassName={innerClassName}
				responsiveMultiplier={secondaryResponsiveMultiplier}
				boxRatio={secondaryBoxRatio}
				useTileWithSecondary={this.props.useTileWithSecondary}
				isPhotoAlbumGridTile={this.props.item.type === PHOTO_ALBUM_GRID_TILE}
				isMobile={this.props.isMobile}
				lazyImageLoading={this.props.lazyImageLoading}
			                        />;
		}

		let shadowGradient;
		if (this.props.showShadowGradient) {
			shadowGradient = <div className="context-box--media-box__gallery--contests-shadow" />;
		}

		let contestBadge;
		if (item.contestPlace && item.contestPlace > 0 && item.contestPlace < 4) {
			contestBadge =
				<span className={"contents__placing -show-on-hover h-color-fg icon-actor -icon-award-user-ranking-" + item.contestPlace} />;
		}

		let tileContent;
		if (item.targetUrl) {
			const linkClassName = (item.isShowPlateOnHover === true ? '-visible-on-hover' : '');

			tileContent = (
				<a className={linkClassName}
				   href={item.targetUrl}
				   onClick={this.onClickFn}
				>
					<div className={contentBoxClassName}>
						{shadowGradient}
						{pictureBoxes}
						{secondaryPictureBoxes}
						{contestBadge}
						{item.isDildocontrolAvailable && item.isOnline && <DildocontrolOverlay />}
						{/* plates */}
						{this.props.children}
					</div>
				</a>
			);
		} else {
			tileContent = (
				<div className="context-box--media-box h-over-hidden clearfix">
					{shadowGradient}
					{pictureBoxes}
					{secondaryPictureBoxes}
					{contestBadge}
					{item.isDildocontrolAvailable && item.isOnline && <DildocontrolOverlay />}
					{/* plates */}
					{this.props.children}
				</div>
			);
		}

		return (
			<div className={tileClassName} onMouseEnter={this.onHover}>
				{tileContent}
			</div>
		);
	}
}

DefaultTile.propTypes = {
	children:                PropTypes.node,
	className:               PropTypes.string,
	contentBoxClassModifier: PropTypes.string,
	isMobile:                PropTypes.bool,
	item:                    PropTypes.object.isRequired,
	onClickFn:               PropTypes.func,
	useRatio:                PropTypes.bool,
	useTileWithSecondary:    PropTypes.bool,
	tileIndex:               PropTypes.number.isRequired,
	usePjax:                 PropTypes.bool,
	showShadowGradient:      PropTypes.bool,
	ignorePjaxScroll:        PropTypes.bool,
	hasMultipleItems:        PropTypes.bool,
	lazyImageLoading:        PropTypes.bool,
	navigateTo:              PropTypes.func,
};

DefaultTile.defaultProps = {
	contentBoxClassModifier: '',
	isMobile:                false,
	useRatio:                false,
	useTileWithSecondary:    false,
	usePjax:                 false,
	showShadowGradient:      false,
	ignorePjaxScroll:        false,
	hasMultipleItems:        false,
	lazyImageLoading:        false,
};

export default DefaultTile;
