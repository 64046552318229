import React     from 'react';
import PropTypes from 'prop-types';


class NewCategoryOverviewPlate extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="row context-box--media-box__plate -newcategory-plate-extended">
				<div className="context-box--media-box__newmaincategories-box-plate box--fixed-ratio__content h-text-align-center">
					<div className="h-disp-table-cell h-vertical-align-middle">
						<div className="context-box--media-box__newmaincategories-plate-categorie">
							<span className="h-text-bold">{this.props.category}</span> ({this.props.actorsAllCount})
						</div>
					</div>
				</div>
			</div>
		);
	}
}

NewCategoryOverviewPlate.propTypes = {
	category:       PropTypes.string.isRequired,
	actorsAllCount: PropTypes.number.isRequired,
};

export default NewCategoryOverviewPlate;

