import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';
import Flux         from '../../flux/Flux';
import VXButton               from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_SPECIAL}   from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}   from "../SimpleElements/VXButton/VXButtonConfig";
import STYLES                 from "../SimpleElements/VXButton/EnumVXButton";

class ProSearchSeparatorTile extends React.Component {

	static deleteFilterFn(event) {
		Flux.ProSearch.deleteFilter();

		if ((typeof event === 'object') && event.cancelable) {
			event.preventDefault();
		}
	}

	constructor(props) {
		super(props);
	}

	render() {
		let contentBoxClassName = 'content-box';

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		return (
			<div className={this.props.className}>
				<div className={contentBoxClassName}>
					<figure className="separator-tile -is-start-page">
						<div className="separator-tile__content">
							<p className="separator-tile__text">{this.props.tileText}</p>
							{this.props.tileSubline && this.props.isFilterResetAvailable &&
								<span className="separator-tile__teaser h-cursor-pointer" onClick={ProSearchSeparatorTile.deleteFilterFn}
								      dangerouslySetInnerHTML={{__html: this.props.tileSubline}}
								/>}
							{!this.props.isMobile && <div className="separator-tile__separator"></div>}
							{this.props.isFilterResetAvailable && this.props.isMobile &&
								<VXButton color={COLOR_TYPE_SPECIAL}
									line1={[new VXButtonLineConfig(Translations.get('ResetFilter'))]}
									onClick={ProSearchSeparatorTile.deleteFilterFn}
									size={STYLES.SIZE.SMALL}
								/>
							}
							{this.props.tileInfo &&
								<span className={"separator-tile__teaser" + (this.props.isMobile ? " separator-tile__teaser--button" : "")}>{this.props.tileInfo}</span>}
							<i className="icon -icon-double-arrow-down-line separator-tile__icon"></i>
						</div>
					</figure>
				</div>
			</div>
		);
	}
}

ProSearchSeparatorTile.propTypes = {
	className:               PropTypes.string.isRequired,
	contentBoxClassModifier: PropTypes.string,
	tileText:                PropTypes.string.isRequired,
	tileSubline:             PropTypes.string,
	tileInfo:                PropTypes.string,
	isFilterResetAvailable:  PropTypes.bool,
	isMobile:                PropTypes.bool,
};

ProSearchSeparatorTile.defaultProps = {
	contentBoxClassModifier: '-padding-equal-smooth',
	isMobile:                false,
};

export default ProSearchSeparatorTile;
