import React        from 'react';
import PropTypes    from 'prop-types';
import {VXPay}      from '../../utils/VXPay';
import Translations from '../../utils/Translations';


class AvsPopUpTile extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			showAvsPopUpNotification: false,
		};

		this.hideAvsPopUp = this.hideAvsPopUp.bind(this);
		this.openAvs      = this.openAvs.bind(this);
		this.showAvsPopUp = this.showAvsPopUp.bind(this);
	}

	hideAvsPopUp() {
		this.setState({
			showAvsPopUpNotification: false,
		});
	}

	openAvs(e) {
		e.preventDefault();
		VXPay.openAVS();
	}

	showAvsPopUp() {
		this.setState({
			showAvsPopUpNotification: true,
		});
	}

	render() {
		return (
			<div className={'badge__avs-container'}
			     onMouseEnter={this.showAvsPopUp}
			     onMouseLeave={this.hideAvsPopUp}
			>
				<span className="badge__FSK-16 h-text-uppercase" onClick={this.openAvs}>
					{Translations.get('Over18')}
				</span>

				{this.state.showAvsPopUpNotification && <div className='badge__avs-pop-up'>
					<div> {this.props.actorName} {Translations.get('AvsPopUpTileTitle')} </div>
					<div> {Translations.get('AvsPopUpTileHeader')}</div>
					<div onClick={this.openAvs}>
						{Translations.get('AvsPopUpTileDescription')}
					</div>
					<span className="badge__avs-arrow"></span>
				</div>}

			</div>
		);
	}
}

AvsPopUpTile.propTypes = {
	actorName:        PropTypes.string,
	isPayingCustomer: PropTypes.bool,
};

export default AvsPopUpTile;