import React                    from 'react';
import PropTypes                from 'prop-types';
import ChatPlate                from '../ChatPlate';
import ActorPlate               from '../ActorPlate';
import NewActorPlate            from './NewActorPlate';
import FavoritePlate            from '../FavoritePlate';
import ActorPlateExtendedMobile from '../../VXMobile/TileGrid/ActorPlateExtended';
import ActorPlateExtended       from '../ActorPlateExtended';
import ActorPlateLight          from '../ActorPlateLight';
import ActorInterviewPlate      from '../ActorInterviewPlate';
import {formatDuration}         from '../../../utils/CommonUtils';
import ActorTextPlate           from '../ActorTextPlate';
import CategoryOverviewPlate    from '../CategoryOverviewPlate';
import NewCategoryOverviewPlate from '../NewCategoryOverviewPlate';
import ActorTop100Plate         from '../ActorTop100Plate';
import ActorLocationPlate       from '../ActorLocationPlate';
import ActorTopFavoritePlate    from '../ActorTopFavoritePlate';
import ActorBirthdayPlate       from '../ActorBirthdayPlate';
import ActorBirthdayPlateLight  from '../ActorBirthdayPlateLight';
import ActorTopRatedChatPlate   from '../ActorTopRatedChatPlate';
import ContestPlate             from '../ContestPlate';
import CoverPlate               from '../CoverPlate';
import CoverPlateLight          from '../CoverPlateLight';
import MagazinePlate            from '../MagazinePlate';
import Translations             from '../../../utils/Translations';
import SpecialAlbumPlate        from '../SpecialAlbumPlate';
import DefaultPlate             from '../DefaultPlate';
import Tile                     from './../Tile';
import Flux                     from '../../../flux/Flux';
import VideoStarPlate           from "../VideoStarPlate";

const COVER_MEDIA_TYPE_VIDEO = 'video';
const COVER_MEDIA_TYPE_POST  = 'post';
const MEDIA_TYPE_PHOTO_ALBUM = 'photo_album';

/**
 * @link https://jobs.zalando.com/tech/blog/the-factory-pattern-in-react/?gh_src=4n3gxh1
 */
class PlateFactory extends React.Component {

    /**
     * @param {string} type
     * @return {boolean}
     */
    static isCoverMediaType(type) {
        return [COVER_MEDIA_TYPE_VIDEO, COVER_MEDIA_TYPE_POST].indexOf(type) === -1;
    }

    /**
     * Responsive!
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            window: Flux.Browser.getWindowSize(),
        };

        this.onResize   = this.onResizeHandler.bind(this);
        this._isMounted = false;
    }

    UNSAFE_componentWillMount() {
        this._isMounted = true;
        Flux.Browser.addWindowResizeListener(this.onResize);
    }

    componentWillUnmount() {
        this._isMounted = false;
        Flux.Browser.removeWindowResizeListener(this.onResize);
    }

    /**
     * Responsive!
     */
    onResizeHandler() {
        if (this._isMounted) {
            this.setState({
                window: Flux.Browser.getWindowSize(),
            });
        }
    }

    render() {
        let hintText  = '';
        let iconClass = '';

        switch (this.props.data.plateType) {
            case PlateFactory.type.EXTENDED_PLATE:
            case PlateFactory.type.NO_PLATE:
                return null;

            case PlateFactory.type.CHAT_PLATE:
                return (
                        <ChatPlate
                                actorName={this.props.data.actorName}
                                actorAge={this.props.data.actorAge}
                                chatDurationString={this.props.data.chatDurationString}
                                chatDateString={this.props.data.chatDateString}
                                isModelGuestChatFavorite={this.props.data.isModelGuestChatFavorite}
                                isOnline={this.props.data.isOnline}
                                actorId={this.props.data.actorId}
                        />
                );

            case PlateFactory.type.ACTOR_PLATE:
                return (
                        <ActorPlate name={this.props.data.name}
                                    age={this.props.data.age}
                                    spokenLanguages={this.props.data.spokenLanguages}
                                    showSpokenLanguages={this.props.data.showSpokenLanguages}
                                    totalVideosCount={this.props.data.totalVideosCount}
                                    headlineType={this.props.headlineType}
                        />
                );

            case PlateFactory.type.NEW_ACTOR_PLATE:
                return (
                        <NewActorPlate
                                name={this.props.data.name}
                                age={this.props.data.age}
                                sublineText={this.props.data.sublineText}
                                spokenLanguages={this.props.data.spokenLanguages}
                                actorId={this.props.data.actorId}
                                isModelGuestFavorite={this.props.data.isModelGuestFavorite}
                                targetUrl={this.props.data.targetUrl}
                        />
                );

            case PlateFactory.type.FAVORITE_PLATE:
                return (
                        <FavoritePlate actorName={this.props.data.actorName}
                                       actorId={this.props.data.actorId}
                                       actorAge={this.props.data.actorAge}
                                       isModelGuestChatFavorite={this.props.data.isModelGuestChatFavorite}
                        />
                );

            case PlateFactory.type.ACTOR_PLATE_EXTENDED:
                if (this.props.isMobile) {
                    return (
                            <ActorPlateExtendedMobile
                                    actorId={this.props.data.actorId}
                                    name={this.props.data.name}
                                    age={this.props.data.age}
                                    isOnline={this.props.data.isOnline}
                                    spokenLanguages={this.props.data.spokenLanguages}
                                    multiChatPrice={this.props.data.multiChatPrice}
                                    singleC2CChatPrice={this.props.data.singleC2CChatPrice}
                                    guestIsLoggedIn={this.props.guestIsLoggedIn}
                                    isModelGuestFavorite={this.props.data.isModelGuestFavorite}
                                    addedFormatted={this.props.data.addedFormatted ? this.props.data.addedFormatted : null}
                                    freeChat={this.props.data.freeChat ? this.props.data.freeChat : null}
                                    isBannedCountryCode={this.props.isBannedCountryCode}
                                    actorLink={this.props.actorLink}
                                    isCurrentlyTicketShow={this.props.data.isCurrentlyTicketShow}
                                    guestHasTicket={this.props.data.guestHasTicket}
                            />
                    );
                }

                if (this.state.window.width > 991) {
                    return (
                            <ActorPlateExtended
                                    actorId={this.props.data.actorId}
                                    name={this.props.data.name}
                                    age={this.props.data.age}
                                    isOnline={this.props.data.isOnline}
                                    guestIsLoggedIn={this.props.guestIsLoggedIn}
                                    spokenLanguages={this.props.data.spokenLanguages}
                                    multiChatPrice={this.props.data.multiChatPrice}
                                    singleC2CChatPrice={this.props.data.singleC2CChatPrice}
                                    isModelGuestFavorite={this.props.data.isModelGuestFavorite}
                                    // addedFormatted={this.props.data.addedFormatted ? this.props.data.addedFormatted : null}
                                    freeChat={this.props.data.freeChat ? this.props.data.freeChat : null}
                                    isBannedCountryCode={this.props.isBannedCountryCode}
                                    actorLink={this.props.actorLink}
                                    isCurrentlyTicketShow={this.props.data.isCurrentlyTicketShow}
                                    guestHasTicket={this.props.data.guestHasTicket}
                                    gaString={this.props.gaString}
                                    isOnlineMobileVideocall={this.props.data.isOnlineMobileVideocall}
                            />
                    );
                }

                return (
                        <ActorPlate name={this.props.data.name}
                                    age={this.props.data.age}
                                    spokenLanguages={this.props.data.spokenLanguages}
                                    showSpokenLanguages={this.props.data.showSpokenLanguages}
                                    totalVideosCount={this.props.data.totalVideosCount}
                                    headlineType={this.props.headlineType}
                        />
                );

            case PlateFactory.type.ACTOR_PLATE_LIGHT:
                return <ActorPlateLight name={this.props.data.name} age={this.props.data.age} />;

            case PlateFactory.type.FAVORITE_EXTENDED_PLATE:
                return null;

            case PlateFactory.type.VIDEO_INTERVIEW_PLATE:
                return (
                        <ActorInterviewPlate
                                id={this.props.data.id}
                                title={this.props.data.title}
                                sublineText={this.props.data.sublineText}
                                duration={formatDuration(this.props.data.duration)}
                        />
                );

			case PlateFactory.type.ACTOR_TEXT_PLATE:
				return (
					<ActorTextPlate
						title={this.props.data.title}
						sublineText={this.props.data.sublineText}
						albumType={this.props.data.albumType}
						albumId={this.props.data.albumId}
						actorId={this.props.data.actorId}
						albumPrice={this.props.data.albumPrice}
						targetUrl={this.props.data.targetUrl}
						navigateTo={this.props.navigateTo}
						needAvs={this.props.data.needAvs}
						needBuying={this.props.data.needBuying}
					/>
				);

            case PlateFactory.type.CATEGORY_PLATE:
                return (
                        <CategoryOverviewPlate
                                category={this.props.data.category}
                                actorsOnlineCount={this.props.data.categoryOnlineActorsCount}
                                actorsAllCount={this.props.data.categoryAllActorsCount}
                                isActorsInfoHidden={this.props.data.isActorsInfoHidden}
                        />
                );

            case PlateFactory.type.NEW_CATEGORY_PLATE:
                return (
                        <NewCategoryOverviewPlate
                                category={this.props.data.category}
                                actorsAllCount={this.props.data.categoryAllActorsCount}
                        />
                );

            case PlateFactory.type.CATEGORY_SPECIAL_PLATE:
                if (!this.props.isMobile) {
                    return null;
                }

                return (
                        <div className="category-tile__name -ismobileplate"
                             dangerouslySetInnerHTML={{__html: this.props.data.content}}
                        />
                );

            case PlateFactory.type.ACTOR_TOP100_PLATE:
                return (
                        <ActorTop100Plate
                                actorName={this.props.data.actorName}
                                actorAge={this.props.data.actorAge}
                                actorPosition={this.props.data.actorPosition}
                        />
                );

            case PlateFactory.type.ACTOR_LOCATION_PLATE:
                return (
                        <ActorLocationPlate
                                actorName={this.props.data.actorName}
                                actorAge={this.props.data.actorAge}
                                distance={this.props.data.distance}
                                actorLocation={this.props.data.actorLocation}
                        />
                );

            case PlateFactory.type.ACTOR_TOP_FAVORITE_PLATE:
                return (
                        <ActorTopFavoritePlate
                                actorName={this.props.data.actorName}
                                actorAge={this.props.data.actorAge}
                                actorPosition={this.props.data.actorPosition}
                                pinnedCount={this.props.data.pinnedCount}
                        />
                );

            case PlateFactory.type.ACTOR_BIRTHDAY_PLATE:
                return (
                        <ActorBirthdayPlate
                                name={this.props.data.name}
                                age={this.props.data.age}
                                birthdayText={this.props.data.birthdayText}
                        />
                );

            case PlateFactory.type.ACTOR_BIRTHDAY_PLATE_LIGHT:
                return (
                        <ActorBirthdayPlateLight
                                name={this.props.data.name}
                                age={this.props.data.age}
                                birthdayText={this.props.data.birthdayText}
                        />
                );

            case PlateFactory.type.ACTOR_TOP_RATED_CHAT_PLATE:
                return (
                        <ActorTopRatedChatPlate
                                actorName={this.props.data.actorName}
                                actorAge={this.props.data.actorAge}
                                actorPosition={this.props.data.actorPosition}
                                satisfactionRate={this.props.data.satisfactionRate}
                                countRatings={this.props.data.countRatings}
                        />
                );

            case PlateFactory.type.CONTEST_PLATE:
                if (typeof this.props.data.isLatestContest !== 'undefined' && this.props.data.isLatestContest) {
                    return null;
                }
                return <ContestPlate contestMediaType={this.props.data.contestMediaType} contestTitle={this.props.data.contestTitle} />;

            case PlateFactory.type.COVER_PLATE:
                const className = PlateFactory.isCoverMediaType(this.props.data.mediaType) === -1 ? '-icon-videotake-full' : '';

                return (
                        <CoverPlate
                                iconClass={className}
                                item={this.props.data}
                                tileIndex={this.props.tileIndex}
                                totalCount={this.props.totalCount}
                                onClickFn={this.props.onClickFn}
                        />
                );

            case PlateFactory.type.COVER_PLATE_LIGHT:
                return <CoverPlateLight title={this.props.data.title} sublineText={this.props.data.sublineText} />;

            case PlateFactory.type.MAGAZINE_PLATE:
                return (
                        <MagazinePlate
                                title={this.props.data.title}
                                teaserText={this.props.data.teaserText}
                                descText={this.props.data.descText}
                                showButton={this.props.data.showButton}
                                isMobile={this.props.isMobile}
                        />
                );

            case PlateFactory.type.SPECIAL_ALBUM_PLATE:
                hintText = (this.props.data.mediaType === MEDIA_TYPE_PHOTO_ALBUM)
                        ? this.props.data.elementCount + ' ' + Translations.get(this.props.data.elementCount === 1 ? 'Picture' : 'Pictures')
                        : formatDuration(this.props.data.duration);

                hintText += ' ' + Translations.get('By').toLowerCase() + ' ' + this.props.data.actorName;

                return <SpecialAlbumPlate hintText={hintText} item={this.props.data} />;

            case PlateFactory.type.VIDEO_STAR_PLATE:
                return (
                        <VideoStarPlate videosCount={this.props.data.totalVideosCount}
                                        newVideosCount={this.props.data.newVideosCount}
                                        actorName={this.props.data.name}
                                        actorAge={this.props.data.age}
                        />
                );

            case PlateFactory.type.DEFAULT_PLATE:
            default:
                let isPinnedPhoto = false;

                if (this.props.data.type === Tile.type.PHOTO_ALBUM_GRID_TILE && !this.props.data.isPinnedPhoto) {
                    iconClass = '-icon-image-album-full';
                    hintText  = this.props.data.elementCount + ' ' + Translations.get(this.props.data.elementCount > 1 ? 'Pictures' : 'Picture');
                } else if (this.props.data.type === Tile.type.VIDEO_ALBUM_GRID_TILE) { // same for interview
                    iconClass = '-icon-play-line';
                    hintText  = formatDuration(this.props.data.duration);
                } else if (this.props.data.isPinnedPhoto) {
                    iconClass     = '-icon-image-full';
                    hintText      = Translations.get('By');
                    isPinnedPhoto = true;
                }

                return (
                        <DefaultPlate isPlateExtended={this.props.useTileWithSecondary}
                                      showButton={this.props.showButton}
                                      isMobile={this.props.isMobile}
                                      iconClass={iconClass}
                                      item={this.props.data}
                                      hintText={hintText}
                                      onClickFn={this.props.onButtonClickFn}
                                      pinOnClickFn={this.props.pinOnClickFn}
                                      showMediaPin={this.props.showMediaPin}
                                      isPinnedPhoto={isPinnedPhoto}
                                      tileIndex={this.props.tileIndex}
                                      isPinned={this.props.data.isPinned}
                        />
                );
        }
    }
}

PlateFactory.type = {
    ACTOR_PLATE:                'ACTOR_PLATE',
    ACTOR_PLATE_EXTENDED:       'ACTOR_PLATE_EXTENDED',
    ACTOR_PLATE_LIGHT:          'ACTOR_PLATE_LIGHT',
    ACTOR_TEXT_PLATE:           'ACTOR_TEXT_PLATE',
    CATEGORY_PLATE:             'CATEGORY_PLATE',
    NEW_CATEGORY_PLATE:         'NEW_CATEGORY_PLATE',
    CHAT_PLATE:                 'CHAT_PLATE',
    DEFAULT_PLATE:              'DEFAULT_PLATE',
    EXTENDED_PLATE:             'EXTENDED_PLATE',
    FAVORITE_PLATE:             'FAVORITE_PLATE',
    FAVORITE_EXTENDED_PLATE:    'FAVORITE_EXTENDED_PLATE',
    NO_PLATE:                   'NO_PLATE',
    VIDEO_INTERVIEW_PLATE:      'VIDEO_INTERVIEW_PLATE',
    ACTOR_TOP100_PLATE:         'ACTOR_TOP100_PLATE',
    ACTOR_LOCATION_PLATE:       'ACTOR_LOCATION_PLATE',
    ACTOR_TOP_FAVORITE_PLATE:   'ACTOR_TOP_FAVORITE_PLATE',
    ACTOR_BIRTHDAY_PLATE:       'ACTOR_BIRTHDAY_PLATE',
    ACTOR_BIRTHDAY_PLATE_LIGHT: 'ACTOR_BIRTHDAY_PLATE_LIGHT',
    ACTOR_TOP_RATED_CHAT_PLATE: 'ACTOR_TOP_RATED_CHAT_PLATE',
    COVER_PLATE:                'COVER_PLATE',
    COVER_PLATE_LIGHT:          'COVER_PLATE_LIGHT',
    MAGAZINE_PLATE:             'MAGAZINE_PLATE',
    SPECIAL_ALBUM_PLATE:        'SPECIAL_ALBUM_PLATE',
    CONTEST_PLATE:              'CONTEST_PLATE',
    CATEGORY_SPECIAL_PLATE:     'CATEGORY_SPECIAL_PLATE',
    VIDEO_ACTORS_TILE:          'VIDEO_ACTORS_TILE',
    NEW_ACTOR_PLATE:            'NEW_ACTOR_PLATE',
    VIDEO_STAR_PLATE:           'VIDEO_STAR_PLATE',
};

PlateFactory.propTypes = {
    tileIndex:            PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    useTileWithSecondary: PropTypes.bool,
    showButton:           PropTypes.bool,
    isMobile:             PropTypes.bool,
    guestIsLoggedIn:      PropTypes.bool,
    showMediaPin:         PropTypes.bool,
    data:                 PropTypes.shape({
        id:                        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        elementCount:              PropTypes.number,
        type:                      PropTypes.string,
        actorName:                 PropTypes.string,
        actorId:                   PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        actorAge:                  PropTypes.number,
        name:                      PropTypes.string,
        mediaType:                 PropTypes.string,
        age:                       PropTypes.number,
        plateType:                 PropTypes.string.isRequired,
        isPinnedPhoto:             PropTypes.bool,
        duration:                  PropTypes.number,
        descText:                  PropTypes.string,
        teaserText:                PropTypes.string,
        title:                     PropTypes.string,
        sublineText:               PropTypes.string,
        contestMediaType:          PropTypes.string,
        countRatings:              PropTypes.number,
        satisfactionRate:          PropTypes.number,
        actorPosition:             PropTypes.number,
        pinnedCount:               PropTypes.number,
        distance:                  PropTypes.number,
        categoryAllActorsCount:    PropTypes.number,
        categoryOnlineActorsCount: PropTypes.number,
        albumPrice:                PropTypes.number,
        albumId:                   PropTypes.number,
        albumType:                 PropTypes.string,
        contestTitle:              PropTypes.string,
        category:                  PropTypes.string,
        birthdayText:              PropTypes.string,
        actorLocation:             PropTypes.string,
        targetUrl:                 PropTypes.string,
        showButton:                PropTypes.bool,
        isCurrentlyTicketShow:     PropTypes.bool,
        guestHasTicket:            PropTypes.bool,
        isOnline:                  PropTypes.bool,
        needBuying:                PropTypes.bool,
        showSpokenLanguages:       PropTypes.bool,
        spokenLanguages:           PropTypes.arrayOf(PropTypes.string),
        needAvs:                   PropTypes.bool,
        isActorsInfoHidden:        PropTypes.bool,
        isModelGuestFavorite:      PropTypes.bool,
        isModelGuestChatFavorite:  PropTypes.bool,
        content:                   PropTypes.any,
        freeChat:                  PropTypes.object,
        multiChatPrice:            PropTypes.number,
        singleC2CChatPrice:        PropTypes.number,
        addedFormatted:            PropTypes.string,
        chatDurationString:        PropTypes.string,
        chatDateString:            PropTypes.string,
        totalVideosCount:          PropTypes.number,
        newVideosCount:            PropTypes.number,
        isLatestContest:           PropTypes.bool,
        isOnlineMobileVideocall:   PropTypes.bool,
        isPinned:                  PropTypes.bool,
    }).isRequired,
    totalCount:           PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClickFn:            PropTypes.func,
    pinOnClickFn:         PropTypes.func,
	navigateTo:           PropTypes.func,
	onButtonClickFn:      PropTypes.func,
	isBannedCountryCode:  PropTypes.bool,
	actorLink:            PropTypes.string,
	headlineType:         PropTypes.oneOf(Object.values(Flux.Constants.Headlines)),
	gaString:             PropTypes.string
};

PlateFactory.defaultProps = {
    data:                {
        plateType:       PlateFactory.type.DEFAULT_PLATE,
        spokenLanguages: ['de'],
    },
    isMobile:            false,
    isBannedCountryCode: false,
    gaString:            "",
};

export default PlateFactory;
