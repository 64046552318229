import React     from 'react';
import PropTypes from 'prop-types';
import '../../../src/VX/Asset/Less/VX/07-components/base/voting.less';

const maxStars = 5;

class Voting extends React.Component {

	static getEmptyStar() {
		return <i className="icon -icon-star-line" />;
	}

	static getHalfStar() {
		return <i className="icon -icon-star-half-full" />;
	}

	static getFullStar() {
		return <i className="icon -icon-star-full" />;
	}

	constructor(props) {
		super(props);

		this.state = {
			rating:     this.props.initialRating,
			viewRating: null,
			frozen:     this.props.frozen,
		};

		this.vote           = this.vote.bind(this);
		this.onLeave        = this.onLeave.bind(this);
		this.onHover        = this.onHover.bind(this);
		this.getStarWrapper = this.getStarWrapper.bind(this);
		this.getRating      = this.getRating.bind(this);
	}

    UNSAFE_componentWillReceiveProps(newProps) {
		// props have changed? otherwise will always overwrite the state
		if (newProps.initialRating !== this.props.initialRating
			|| newProps.frozen !== this.props.frozen) {
			this.setState({
				rating: newProps.initialRating,
				frozen: newProps.frozen,
			});
		}
	}

	vote(e, index) {
		if (!this.state.frozen && !this.props.readonly) {

			// update state
			this.setState({
				rating:     index,
				viewRating: index,
				frozen:     this.props.onetime ? true : this.state.frozen,
			});

			if (typeof this.props.onVoteClick === 'function') {
				this.props.onVoteClick(index, e);
			}
		}

		if (e.preventDefault) {
			e.preventDefault();
		}
	}

	onLeave() {
		if (!this.state.frozen) {
			this.setState({
				viewRating: null,
			});
		}
	}

	onHover(i) {
		if (!this.state.frozen && !this.props.readonly) {
			this.setState({
				viewRating: i,
			});
		}
	}

	getStarWrapper(i, node) {
		const onMouseEnter = () => {
			this.onHover(i);
		};
		const onClick      = (e) => {
			this.vote(e, i);
		};

		return <a href="#" key={i} className={'voting__star'} onMouseEnter={onMouseEnter} onClick={onClick}>{node}</a>;
	}

	getRating() {
		return this.state.rating;
	}

	render() {
		const stars  = [];
		const rating = this.state.viewRating !== null ? this.state.viewRating : this.state.rating;

		let roundRating;
		if (this.props.roundRatingFunc && typeof this.props.roundRatingFunc === 'function') {
			roundRating = this.props.roundRatingFunc(rating);
		} else {
			roundRating = Math.max(0, Math.min((maxStars * Math.round((rating * 10) / maxStars)) / 10, maxStars));
		}
		const fullStars   = Math.floor(roundRating);
		const halfStars   = (roundRating - fullStars > 0) ? 1 : 0;
		const emptyStars  = maxStars - fullStars - halfStars;

		let i = 1, c = 0;
		for (; c < fullStars; c++) {
			stars[i + c] = this.getStarWrapper(i + c, Voting.getFullStar());
		}

		for (i += c, c = 0; c < halfStars; c++) {
			stars[i + c] = this.getStarWrapper(i + c, Voting.getHalfStar());
		}

		for (i += c, c = 0; c < emptyStars; c++) {
			stars[i + c] = this.getStarWrapper(i + c, Voting.getEmptyStar());
		}

		return (
			<div className="voting" onMouseLeave={this.onLeave}>
				{stars}
			</div>
		);
	}
}

Voting.propTypes = {
	initialRating:   PropTypes.number,
	onetime:         PropTypes.bool,
	onVoteClick:     PropTypes.func,
	frozen:          PropTypes.bool,
	readonly:        PropTypes.bool,
	roundRatingFunc: PropTypes.func,
};

Voting.defaultProps = {
	initialRating:   0,
	onetime:         false,
	frozen:          true,
	readonly:        false,
	roundRatingFunc: null,
};

export default Voting;
