import React           from 'react';
import PropTypes       from 'prop-types';
import PictureBoxes    from './PictureBoxes';
import GermanOnlyBadge from "../SimpleElements/GermanOnlyBadge";

class MagazineTile extends React.Component {

	constructor(props) {
		super(props);
		this.onClickFn = this.onClickFn.bind(this);
	}

	onClickFn(e) {
		if (this.props.isMobile) {
			this.props.onClickFn(e, this.props.item.id);
		}
	}

	render() {
		let responsiveMultiplier = 1;

		if (this.props.isMobile) {
			responsiveMultiplier = 1;
		}

		const ratioClassName = this.props.item && this.props.item.showButton ? '-ratio100_53' : '-ratio10_7';
		const outerClassName = (this.props.useRatio ? 'box--fixed-ratio ' + ratioClassName : '');
		const innerClassName = this.props.useRatio ? 'box--fixed-ratio__content' : '';
		const pictureBoxes   = <PictureBoxes pictures={this.props.previewPictureUrls}
		                                     useIndexForKey={true}
		                                     outerClassName={outerClassName}
		                                     innerClassName={innerClassName}
		                                     responsiveMultiplier={responsiveMultiplier}
											 lazyImageLoading={this.props.lazyImageLoading}
		                       />;

		return (
			<div className={this.props.className} onClick={this.onClickFn}>
				<div className="magazinehighlight__container" style={{position: "relative"}}>
					{this.props.item.showButton && <GermanOnlyBadge />}
					<div className="magazinehighlight__content">
						<a href={this.props.targetUrl} className="magazinehighlight__morelink js-pjax-link">
							{pictureBoxes}
							{this.props.children}
						</a>
					</div>
				</div>
			</div>
		);
	}
}

MagazineTile.propTypes = {
	className:          PropTypes.string,
	cols:               PropTypes.number,
	isMobile:           PropTypes.bool,
	useRatio:           PropTypes.bool,
	targetUrl:          PropTypes.string,
	previewPictureUrls: PropTypes.arrayOf(
		PropTypes.array
	),
	children:           PropTypes.node,
	onClickFn:          PropTypes.func,
	item:               PropTypes.object.isRequired,
	lazyImageLoading:   PropTypes.bool,
};

MagazineTile.defaultProps = {
	className:          '',
	cols:               1,
	isMobile:           false,
	useRatio:           true,
	targetUrl:          '',
	previewPictureUrls: [],
	children:           null,
	onClickFn:          () => {
	},
	item:               {},
	lazyImageLoading:   false,
};

export default MagazineTile;