import React from 'react';
import Flux  from '../../../flux/Flux.js';

class ActorPictureNoPreviews extends React.PureComponent {
	render() {
		return (
			<div>
				<div className="actor-image -pos-0 -no-avatar-actor">
					<img src={Flux.Constants.Placeholders.Avatar.FEMALE} alt="female placeholder" />
				</div>
			</div>
		);
	}
}

export default ActorPictureNoPreviews;
