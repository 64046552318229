import React        from 'react';
import PropTypes    from 'prop-types';
import PictureBoxes from './PictureBoxes';
import ReloadHelper from '../../utils/ReloadHelper';
import Flux         from '../../flux/Flux';

class CategoryTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		if (typeof this.props.onClickFn === 'function') {
			this.props.onClickFn(e, this.props.targetUrl);
		}

		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.targetUrl, e);
		}
	}

	render() {
		const categorystyle      = {height: '100%'};
		const outerClassName     = 'context-box--media-box__maincategories-actor-item';
		const containerClassName = 'context-box--media-box__maincategories-actors-list box--fixed-ratio__content';
		const pictureBoxes       = <PictureBoxes pictures={this.props.actorsPreviewPicture}
		                                         useIndexForKey={true}
		                                         outerClassName={outerClassName}
		                                         containerClassName={containerClassName}
		                                         responsiveMultiplier={2}
		                           />;

		const pictureClass = "maincategories-picture -" + this.props.imageClassKey;

		let tileContent;
		if (this.props.isMobile) {
			// special handling for list tile grid mode
			if (this.props.gridMode === Flux.Constants.TileGridModes.LIST) {
				tileContent = (
					<div className={this.props.className} onClick={this.onClick}>
						<div className="category-list-tile__name">
							{this.props.categoryName}
							{!this.props.isActorsInfoHidden && <span className="category-list-tile__cams">({this.props.actorsCount})</span>}
						</div>
						<div className="category-list-tile__right">
							<div className={pictureClass + " category-list-tile__image"}></div>
							<div className="category-list-tile__image-blur-container">
								<div className={"category-list-tile__image-blur-overlay"}></div>
								<div className={pictureClass + " category-list-tile__image category-list-tile__image--blur"}></div>
							</div>
							<div className="category-list-tile__icon"><i className="icon -icon-single-arrow-right-line"></i></div>
						</div>
					</div>
				);
			} else {
				tileContent = (
					<div className={this.props.className}>
						<a href={this.props.targetUrl} className={'category__overview-tilelink'} onClick={this.onClick}>
							<div className="category-tile__container">
								<div className={pictureClass + ' category-tile__picture'}>
								</div>
								<h2 className="category-tile__name">{this.props.categoryName}</h2>
							</div>
						</a>
					</div>
				);
			}

		} else {
			tileContent = (
				<div className={this.props.className}>
					<a href={this.props.targetUrl} className={'category__overview-tilelink'} onClick={this.onClick}>
						<div className="context-box--media-box -full-border clearfix">
							<div className="box--fixed-ratio -ratio4_3">
								<div className="context-box--media-box__maincategories-picture box--fixed-ratio__content">
									<div className={pictureClass} style={categorystyle}>
										{
											this.props.languageCode &&
											<div className="context-box--media-box__maincategories-flag">
												<span className={"badge-language -no-border-radius -size-xl -lang" + this.props.languageCode} />
											</div>
										}
										<div className={'context-box--media-box__maincategories-name'}>
											{this.props.actorName}
										</div>
									</div>
								</div>
								{pictureBoxes}
								{this.props.children}
							</div>
						</div>
					</a>
				</div>
			);
		}

		return tileContent;
	}
}

CategoryTile.defaultProps = {
	gridMode:           Flux.Constants.TileGridModes.GRID,
	isActorsInfoHidden: false,
};

CategoryTile.propTypes = {
	actorName:            PropTypes.string.isRequired,
	actorsCount:          PropTypes.number,
	actorsPreviewPicture: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
	categoryName:         PropTypes.string,
	children:             PropTypes.node,
	className:            PropTypes.string.isRequired,
	gridMode:             PropTypes.string,
	imageClassKey:        PropTypes.string.isRequired,
	isActorsInfoHidden:   PropTypes.bool,
	isMobile:             PropTypes.bool,
	languageCode:         PropTypes.string.isRequired,
	onClickFn:            PropTypes.func,
	targetUrl:            PropTypes.string.isRequired,
	usePjax:              PropTypes.bool,
};

export default CategoryTile;
