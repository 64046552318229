import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';


class ActorTopRatedChatPlate extends React.Component {

	render() {
		const satisfactionComponent = (className) => {
			const baseClass = 'context-box--media-panel__chat--voting';
			return (
				<div className={className}>
					<i className={baseClass + '-rating-progress-like icon -icon-like-full'} />
					<span className={baseClass + '-rating-progress-value'}>
						{Math.round(this.props.satisfactionRate * 100)}%
					</span>
					<span className={baseClass + '-rating-label'}>/ {this.props.countRatings} {Translations.get('ActorProfileChatRatingChats')}</span>
				</div>
			);
		};

		return (
			<div className="context-box--media-box__plate h-clear">
				<div className="context-box--media-box__custombox -top3box">
					{this.props.actorPosition <= 3 &&
					<div className="h-height-full">
						<i className={'icon-actor -icon-award-chat-place-' + this.props.actorPosition + ' -size-md h-left'} />
						<div className="h-flex -direction-column -align-center">
							<div className="h-flex">
								<h3>{this.props.actorName}</h3>
								<span className="context-box--media-panel__actor--age h-ml-5">({this.props.actorAge})</span>
							</div>
							{satisfactionComponent('context-box--media-panel__chat--voting')}
						</div>
					</div>
					}
					{this.props.actorPosition > 3 &&
					<div className="h-height-full">
						<div className="h-flex -direction-column -align-center">
							<div className="h-flex">
								<h3>{this.props.actorName}</h3> 
								<span className="context-box--media-panel__actor--age h-ml-5">({this.props.actorAge})</span>
							</div>
							{satisfactionComponent('context-box--media-box__custombox -description h-left')}
						</div>
					</div>
					}
				</div>
			</div>
		);
	}
}

ActorTopRatedChatPlate.propTypes = {
	actorName:        PropTypes.string.isRequired,
	actorAge:         PropTypes.number.isRequired,
	actorPosition:    PropTypes.number.isRequired,
	countRatings:     PropTypes.number.isRequired,
	satisfactionRate: PropTypes.number.isRequired,
};

export default ActorTopRatedChatPlate;
