import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

class VideoActorsTile extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		let backgroundImage = {};
		if (this.props.background !== '') {
			backgroundImage = {
				'background-image': "url('" + this.props.background + "')",
			};
		}
		let contentBoxClassName = 'content-box';

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		return (
			<div className={this.props.className} style={backgroundImage}>
				<div className={contentBoxClassName}>
					<figure className="context-box--media-box__all-videos">
						<div className="separator-tile__content">
							<p className="separator-tile__content__text">{this.props.tileText}</p>
							<div className="row actor-content-placeholder -all-videos">
								<a onClick={this.props.onClickFn} className="btn -btn-color-special js-pjax-link">
									{Translations.get('AllVideosActorVideos')}</a>
							</div>
						</div>
					</figure>
				</div>
			</div>
		);
	}
}

VideoActorsTile.propTypes = {
	className:               PropTypes.string.isRequired,
	contentBoxClassModifier: PropTypes.string,
	tileText:                PropTypes.string.isRequired,
	background:              PropTypes.string,
	onClickFn:               PropTypes.func.isRequired,
};

VideoActorsTile.defaultProps = {
	contentBoxClassModifier: '-padding-equal-smooth',
	background:              '',
};

export default VideoActorsTile;
