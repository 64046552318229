import React        from "react";
import PropTypes    from 'prop-types';
import ReloadHelper from "../../utils/ReloadHelper";

class VideoStationTile extends React.PureComponent {

	constructor(props) {
		super(props);

		this.classSuffix = '';
		if (this.props.isMobile) {
			this.classSuffix = ' -is-mobile';

			if (this.props.isLandscape) {
				this.classSuffix += ' -is-landscape';
			}
		}

	}

	onClickActor(e) {
		ReloadHelper.reloadWithPjax(e.target.href, e);
	}

	onClickButton(e) {
		if (this.props.videoStationLink) {
			ReloadHelper.reloadWithPjax(this.props.videoStationLink, e);
		}
	}

	getActorRow(actor) {

		let actorOnline = '';
		if (actor.isOnline) {
			actorOnline = ' --online';
		}

		return (
			<div className={"video-station-tile-actor" + this.classSuffix}>
				<a className={"video-station-tile-actor-link" + this.classSuffix}
				   href={actor.profileLink}
				   onClick={this.onClickActor}
				>
					{actor.name}
				</a>
				<span className={"video-station-tile-actor-img-container" + actorOnline + this.classSuffix}>
					<img className={"video-station-tile-actor-img" + this.classSuffix} src={actor.previewPicture} />
				</span>
				<span className={"video-station-tile-actor-name" + this.classSuffix}>{actor.name} ({actor.age})</span>
			</div>
		);
	}


	render() {
		const actors = [];

		for (let i = 0; i < this.props.actors.length; i++) {
			actors.push(this.getActorRow(this.props.actors[i]));
		}

		return (
			<div className={this.props.className}>
				<div className="content-box -padding-equal-smooth">
					<div className="actor-tile">
						<div className={"video-station-tile" + this.classSuffix}>
							<div className={"video-station-tile-headline" + this.classSuffix}>
								Top Video Girls
							</div>
							<div className={"video-station-tile-actor-container" + this.classSuffix}>
								{actors}
							</div>
							<div className={"video-station-tile-button-container" + this.classSuffix}>
								<a href={this.props.videoStationLink}
								   onClick={this.onClickButton}
								   className={"video-station-tile-button" + this.classSuffix}
								>
									Video Station
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

}


VideoStationTile.propTypes = {
	className:        PropTypes.string,
	actors:           PropTypes.array,
	videoStationLink: PropTypes.string.isRequired,
	isMobile:         PropTypes.bool,
	isLandscape:      PropTypes.bool,
};

VideoStationTile.defaultProps = {
	isMobile:    false,
	isLandscape: false,
};


export default VideoStationTile;