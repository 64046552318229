import React     from 'react';
import PropTypes from 'prop-types';

class SpecialAlbumPlate extends React.Component {

	render() {
		return (
			<div className="context-box--media-box__plate">
				<div className="context-box--media-box__title">{this.props.item.title}</div>
				<div className="context-box--media-box__subline h-width-full h-no-margin">
					{this.props.item.likesCount > 0 &&
					<span className={"content-box--media-box__subline__element h-right h-no-margin"}>
						<i className="icon -icon-like-full h-color-highlight" /> <span>{this.props.item.likesCount}</span>
					</span>}
					<span className={'content-box--media-box__subline__element h-no-margin h-color-fg-description'}>{this.props.hintText}</span>
				</div>
			</div>
		);
	}
}

SpecialAlbumPlate.propTypes = {
	hintText: PropTypes.string,
	item:     PropTypes.object,
};

export default SpecialAlbumPlate;