import React     from 'react';
import PropTypes from 'prop-types';
import 'lazysizes';

class ActorTextTile extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {

		const colClass = 'col-lg-' + (this.props.size * 4) + ' col-sm-6 col-xs-12';

		const containerClass = 'context-box--text-box -scheme-' + this.props.colorStyle;

		let style = {};

		if (this.props.previewPictureUrls.length > 0) {
			style = {backgroundImage: 'url(' + this.props.previewPictureUrls + ')', backgroundSize: 'cover', height: '330px', position: 'relative'};
		}

		return (
			<div className={colClass}>
				<div className={containerClass + (this.props.showPlateOnHover ? ' -visible-on-hover' : '')} style={style}>
					{this.props.children}
				</div>
			</div>
		);
	}
}

ActorTextTile.propTypes = {
	size:               PropTypes.number.isRequired,
	colorStyle:         PropTypes.string.isRequired,
	previewPictureUrls: PropTypes.array,
	children:           PropTypes.node,
	showPlateOnHover:   PropTypes.bool,
};

ActorTextTile.defaultProps = {
	size:               1,
	colorStyle:         '',
	previewPictureUrls: '',
	showPlateOnHover:   false,
};

export default ActorTextTile;
