import React        from 'react';
import PropTypes    from 'prop-types';
import PictureBoxes from './PictureBoxes';
import TeaserHelper from '../../utils/TeaserHelper';

class TeaserTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClickFn = this.onClickFn.bind(this);
	}

	onClickFn() {
		if (typeof this.props.item.action !== 'undefined') {
			TeaserHelper.callAction(this.props.item.action);
		}
	}

	render() {
		const item              = this.props.item;
		let contentBoxClassName = 'content-box';

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		let additionalFigureClassName = '';
		if (item.previewPictureUrls.length <= 1) {
			additionalFigureClassName = ' -no-animation';
		}

		const pictureBoxes = <PictureBoxes pictures={item.previewPictureUrls}
		                                   useIndexForClassName={true}
		                                   outerClassName={'actor-image'}
		                     />;

		return <div className={this.props.className}>
			<div className={contentBoxClassName}>
				<div onClick={this.onClickFn}
				     className={"actor-tile h-cursor-pointer" + additionalFigureClassName}
				     id={"actor-tile-" + this.props.tileIndex}
				>
					<div>
						{pictureBoxes}
					</div>
					{this.props.children}
				</div>
			</div>
		</div>;
	}

}

TeaserTile.propTypes = {
	children:                PropTypes.node,
	className:               PropTypes.string,
	contentBoxClassModifier: PropTypes.string,
	item:                    PropTypes.object.isRequired,
	onClickFn:               PropTypes.func,
	tileIndex:               PropTypes.number.isRequired,
};

TeaserTile.defaultProps = {
	className:               'col-lg-3 col-xs-6',
	animateTile:             false,
	animateTouch:            false,
	autoAnimateInView:       false,
	contentBoxClassModifier: '-padding-equal-smooth',
	showContactLayer:        true,
	showButtonProfile:       false,
	showButtonMessenger:     false,
	isMobile:                false,
	onClickFn:               () => {
	},      // params: e, actorData
	usePjax:                 false,
};

export default TeaserTile;