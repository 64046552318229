/* global VXConfig */
'use strict';

import React                    from 'react';
import PropTypes                from 'prop-types';
import Flux                     from '../../flux/Flux';
import DefaultTile              from './DefaultTile';
import ActorTile                from './ActorTile';
import ChatTile                 from './ChatTile';
import ProSearchSeparatorTile   from './ProSearchSeparatorTile';
import CategoryTile             from './CategoryTile';
import NewCategoryTile          from './NewCategoryTile';
import HomePageCategoryTile     from './HomePageCategoryTile';
import CategorySpecialTile      from './CategorySpecialTile';
import Translations             from '../../utils/Translations';
import ActorTextTile            from './ActorTextTile';
import CoverTile                from './CoverTile';
import ContestTile              from './ContestTile';
import CoverTileLight           from './CoverTileLight';
import TeaserTile               from './TeaserTile';
import ProSearchBonusTile       from './ProSearchBonusTile';
import MagazineTile             from './MagazineTile';
import LastTile                 from './LastTile';
import VideoActorsTile          from './VideoActorsTile';
import VideoStationTile         from './VideoStationTile';
import PlateFactory             from './Plate/PlateFactory';
import TileBadgesList           from './Badge/TileBadgesList';
import TileRibbonsList          from './Ribbon/TileRibbonsList';
import NewlyRegisteredActorTile from './Tile/NewlyRegisteredActorTile';
import TileBadgeLive            from './Badge/TileBadgeLive';
import {generateKey}            from '../../utils/CommonUtils';
import withSuspense             from '../HigherOrderComponents/Utility/withSuspense';

const ProSearchWelcomeClipRotatorTile = React.lazy(() => import('./ProSearchWelcomeClipRotatorTile'));
const FavoriteTile                    = React.lazy(() => import('./FavoriteTile'));
const FavoriteExtendedTile            = React.lazy(() => import('./FavoriteExtendedTile'));
const ProSearchMysteryBoxTile         = React.lazy(() => import("./ProSearchMysteryBoxTile"));

class Tile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHidden: false,
        };

        this.setTileHidden = this.setTileHidden.bind(this);
    }

    /**
     * @param {string} type
     * @return {boolean}
     */
    static isHighlightTile(type) {
        return [
            Tile.type.HIGHLIGHT_ACTOR_GRID_TILE,
            Tile.type.HIGHLIGHT_TEASER_GRID_TILE,
        ].indexOf(type) !== -1;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.tileData.tileIdentifier !== nextProps.tileData.tileIdentifier)
                || (this.props.contentBoxClassModifier !== nextProps.contentBoxClassModifier)
                || (this.props.gridTileClassName !== nextProps.gridTileClassName)
                || (this.props.tileIndex !== nextProps.tileIndex)
                || (this.props.animateTile !== nextProps.animateTile)
                || (this.state.isHidden !== nextState.isHidden);
    }

    isBannedCountryCode() {
        return this.props.tileData.bannedCountryCodes && this.props.tileData.bannedCountryCodes.indexOf(VXConfig.guestCountryCode) >= 0;
    }

    setTileHidden() {
        this.setState({
            isHidden: true
        }, () => {
        });
    }

    render() {
        let tile;

        const data            = this.props.tileData;
        const uniqueKey       = generateKey('tile-' + (data.actorName || 'dummy') + "-");
        const isPricedContent = true;
        const badgesClass     = this.props.badgeContainerAdditionalClassName;

        const badges = (
                <TileBadgesList
                        data={data}
                        isMobile={this.props.isMobile}
                        additionalClass={badgesClass}
                        isPricedContent={isPricedContent}
                        proSearchActiveCategory={this.props.proSearchActiveCategory}
                        showOnlyFsk18Badge={this.props.gridConfig.showOnlyFsk18Badge}
                        showPremium={this.props.showPremiumBadge}
                />
        );

        const ribbons = (
                <TileRibbonsList
                        data={data}
                        useTileWithSecondary={this.props.useTileWithSecondary}
                        badgeContainerAdditionalClassName={badgesClass}
                />
        );

        const plate = (
                <PlateFactory
                        data={data}
                        isMobile={this.props.isMobile}
                        totalCount={this.props.gridConfig.totalCount}
                        guestIsLoggedIn={this.props.guestIsLoggedIn}
                        navigateTo={this.props.gridConfig.navigateTo}
                        onButtonClickFn={this.props.onButtonClickFn}
                        showButton={this.props.gridConfig.showButton}
                        showMediaPin={this.props.gridConfig.showMediaPin}
                        onClickFn={this.props.onClickFn}
                        tileIndex={this.props.tileIndex}
                        useTileWithSecondary={this.props.useTileWithSecondary}
                        isBannedCountryCode={this.isBannedCountryCode()}
                        actorLink={data.targetUrl}
                        headlineType={this.props.headlineType}
                        gaString={data.type === Tile.type.HIGHLIGHT_ACTOR_GRID_TILE ? "Top-Amateur" : ""}
                        pinOnClickFn={this.setTileHidden}
                        isHidden={this.state.isHidden}
                />
        );

        // tiles
        switch (data.type) {
            case Tile.type.PROSEARCH_NO_RESULTS_GRID_TILE:
                tile = (
                        <ProSearchSeparatorTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                tileText={Translations.get('ProSearchNoResults')}
                                tileSubline={Translations.get('ProSearchDiscoverSimilarGirlsTeaser')}
                                tileInfo={Translations.get('ProSearchDiscoverSimilarGirlsSeeBelow')}
                                isFilterResetAvailable={true}
                        />
                );
                break;

            case Tile.type.PROSEARCH_BONUS_TILE:
                tile = (
                        <ProSearchBonusTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                isMobile={this.props.isMobile}
                        />
                );
                break;

            case Tile.type.PROSEARCH_SEPARATOR_GRID_TILE:
                tile = (
                        <ProSearchSeparatorTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                tileText={Translations.get('ProSearchDiscoverSimilarGirls')}
                                tileSubline={Translations.get('ProSearchDiscoverSimilarGirlsTeaser')}
                                isFilterResetAvailable={true}
                                tileInfo={Translations.get('ProSearchDiscoverSimilarGirlsSeeBelow')}
                        />
                );
                break;
            case Tile.type.PROSEARCH_MYSTERY_BOX_TILE:
                tile = (
                        withSuspense(<ProSearchMysteryBoxTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                reward={data.reward}
                                rewardVideoUrl={data.rewardVideoUrl}
                                modalBackgroundUrl={data.modalBackgroundUrl}
                                modalBackgroundTabletUrl={data.modalBackgroundTabletUrl}
                                qmDate={data.qmDate}
                                eventId={data.eventId}
                        />)
                );
                break;

            case Tile.type.VIDEO_ACTORS_TILE:
                tile = (
                        <VideoActorsTile
                                key={Date.now()}
                                className={this.props.gridTileClassName}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                tileText={Translations.get('ShowAllVideosActorVideos', this.props.tileData.actorName)}
                                onClickFn={this.props.onClickFn}
                        />
                );
                break;

            case Tile.type.PROSEARCH_WELCOME_CLIP_ROTATOR_TILE:
                tile = (
                        withSuspense(<ProSearchWelcomeClipRotatorTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                playlist={data.playlist}
                        />)
                );
                break;

            case Tile.type.NEWLY_REGISTERED_ACTOR_TILE:
                tile = (
                        <NewlyRegisteredActorTile
                                key={uniqueKey}
                                animateTile={this.props.animateTile}
                                className={this.props.gridTileClassName + ' is-newly-registered'}
                                item={data}
                                isMobile={this.props.isMobile}
                                tileIndex={this.props.tileIndex}
                                showContactLayer={data.showContactLayer}
                                autoAnimateInView={this.props.autoAnimateInView}
                                onClickFn={this.props.onClickFn}
                                onOpenMessengerClick={this.props.onButtonClickFn}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                usePjax={this.props.gridConfig.usePjax}
                                lazyImageLoading={this.props.lazyImageLoading}
                        >
                            <TileBadgeLive className="badge--normal" show={data.isOnline} />
                            {plate}
                        </NewlyRegisteredActorTile>
                );
                break;

            case Tile.type.ACTOR_GRID_TILE:
            case Tile.type.HIGHLIGHT_ACTOR_GRID_TILE:
            case Tile.type.HOMEPAGE_ACTOR_TILE:
                tile = (
                        <ActorTile
                                key={uniqueKey}
                                animateTile={this.props.animateTile}
                                className={this.props.gridTileClassName + (data.type === Tile.type.HIGHLIGHT_ACTOR_GRID_TILE ? ' is-highlight-actor-tile' : '') + (data.isTopAmateur ? ' is-top-amateur' : '')}
                                item={data}
                                isMobile={this.props.isMobile}
                                tileIndex={this.props.tileIndex}
                                showContactLayer={data.showContactLayer}
                                autoAnimateInView={this.props.autoAnimateInView}
                                onClickFn={this.props.onClickFn}
                                onOpenMessengerClick={this.props.onButtonClickFn}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                usePjax={this.props.gridConfig.usePjax}
                                isHighlight={data.type === Tile.type.HIGHLIGHT_ACTOR_GRID_TILE}
                                showButtonMessenger={data.showButtonMessenger}
                                lazyImageLoading={this.props.lazyImageLoading}
                                headlineType={this.props.headlineType}

                        >
                            {badges} {ribbons} {plate}
                        </ActorTile>
                );
                break;

            case Tile.type.HOMEPAGE_FAVORITE_ACTOR_TILE:
                tile = (
                        <ActorTile
                                key={uniqueKey}
                                animateTile={this.props.animateTile}
                                className={this.props.gridTileClassName}
                                item={data}
                                isMobile={this.props.isMobile}
                                tileIndex={this.props.tileIndex}
                                showContactLayer={false}
                                autoAnimateInView={this.props.autoAnimateInView}
                                onClickFn={this.props.onClickFn}
                                onOpenMessengerClick={this.props.onButtonClickFn}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                usePjax={this.props.gridConfig.usePjax}
                                showButtonMessenger={data.showButtonMessenger}
                                lazyImageLoading={this.props.lazyImageLoading}
                        >
                            {badges} {plate}
                        </ActorTile>
                );
                break;

            case Tile.type.HIGHLIGHT_TEASER_GRID_TILE:
                tile = (
                        <TeaserTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                item={data}
                                tileIndex={this.props.tileIndex}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                        >
                            {plate}
                        </TeaserTile>
                );
                break;

            case Tile.type.CHAT_GRID_TILE:
                tile = (
                        <ChatTile
                                actorId={data.actorId}
                                actorName={data.actorName}
                                chatId={data.chatId}
                                chatRating={data.chatRating}
                                className={this.props.gridTileClassName}
                                isMobile={this.props.isMobile}
                                isOnline={data.isOnline}
                                isDildocontrolAvailable={data.isDildocontrolAvailable}
                                profileLink={data.profileLink}
                                previewPictureUrls={data.previewPictureUrls}
                                multiChatPrice={data.multiChatPrice}
                                usePjax={this.props.gridConfig.usePjax}
                                useRatio={true}
                                useThumbsVoting={this.props.useThumbsVoting}
                                isCurrentlyTicketShow={data.isCurrentlyTicketShow}
                                guestHasTicket={data.guestHasTicket}
                                isOnlineMobileVideocall={data.isOnlineMobileVideocall}
                        >
                            {badges} {plate}
                        </ChatTile>
                );
                break;

            case Tile.type.FAVORITE_GRID_TILE:
                tile = (
                        withSuspense(<FavoriteTile
                                actorId={data.actorId}
                                actorName={data.actorName}
                                className={this.props.gridTileClassName}
                                isMobile={this.props.isMobile}
                                isOnline={data.isOnline}
                                isDildocontrolAvailable={data.isDildocontrolAvailable}
                                profileLink={data.profileLink}
                                previewPictureUrls={data.previewPictureUrls}
                                multiChatPrice={data.multiChatPrice}
                                asFavoriteAdded={data.asFavoriteAdded}
                                showTextChatButtonOnly={data.showTextChatButtonOnly}
                                usePjax={this.props.gridConfig.usePjax}
                                lazyImageLoading={this.props.lazyImageLoading}
                        >
                            {badges} {plate}
                        </FavoriteTile>)
                );
                break;

            case Tile.type.FAVORITE_EXTENDED_GRID_TILE:
                tile = (
                        withSuspense(<FavoriteExtendedTile
                                actorId={data.actorId}
                                actorName={data.actorName}
                                actorAge={data.actorAge}
                                className={this.props.gridTileClassName}
                                isOnline={data.isOnline}
                                isDildocontrolAvailable={data.isDildocontrolAvailable}
                                isMobile={this.props.isMobile}
                                previewPictureUrls={data.previewPictureUrls}
                                multiChatPrice={data.multiChatPrice}
                                singleC2CChatPrice={data.singleC2CChatPrice}
                                asFavoriteAdded={data.asFavoriteAdded}
                                newElements={data.newElements}
                                showNewContentBanner={data.showNewContentBanner}
                                targetLink={data.targetUrl}
                                isModelGuestFavorite={data.isModelGuestFavorite}
                                usePjax={this.props.gridConfig.usePjax}
                                messagePrice={data.messagePrice}
                                isEmailNotificationEnabled={data.isEmailNotificationEnabled}
                                isSmsNotificationEnabled={data.isSmsNotificationEnabled}
                                lastOnlineDiffDays={data.lastOnlineDiffDays}
                                newElementTarget={data.newElementTarget}
                                isCurrentlyTicketShow={data.isCurrentlyTicketShow}
                                guestHasTicket={data.guestHasTicket}
                                isMessengerExcluded={data.isMessengerExcluded}
                                isOnlineMobileVideocall={data.isOnlineMobileVideocall}
                        >
                            {badges}
                            {plate}
                        </FavoriteExtendedTile>)
                );
                break;

            case Tile.type.ACTOR_TEXT_TILE:
                tile = (
                        <ActorTextTile
                                size={data.size}
                                colorStyle={data.colorStyle}
                                navigateTo={this.props.gridConfig.navigateTo}
                                previewPictureUrls={data.previewPictureUrls}
                                showPlateOnHover={data.isShowPlateOnHover}
                        >
                            {plate}
                        </ActorTextTile>
                );
                break;

            case Tile.type.CATEGORY_OVERVIEW_TILE:
                tile = (
                        <CategoryTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                targetUrl={data.targetUrl}
                                useRatio={true}
                                imageClassKey={data.imageClassKey}
                                actorsPreviewPicture={data.actorsPreviewPictures}
                                languageCode={data.languageCode}
                                actorName={data.actorName}
                                usePjax={this.props.gridConfig.usePjax}
                                isMobile={this.props.isMobile}
                                gridMode={this.props.gridMode}
                                onClickFn={this.props.onClickFn}
                                categoryName={data.category}
                                actorsCount={data.categoryAllActorsCount}
                                isActorsInfoHidden={data.isActorsInfoHidden}
                        >
                            {badges}
                            {plate}
                        </CategoryTile>
                );
                break;

            case Tile.type.NEW_CATEGORY_OVERVIEW_TILE:
                tile = (
                        <NewCategoryTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                targetUrl={data.targetUrl}
                                imageClassKey={data.imageClassKey}
                                usePjax={this.props.gridConfig.usePjax}
                                onClickFn={this.props.onClickFn}
                                categoryName={data.category}
                                actorsCount={data.categoryAllActorsCount}
                        >
                            {badges} {plate}
                        </NewCategoryTile>
                );
                break;

            case Tile.type.HOMEPAGE_CATEGORY_TILE:
                tile = (
                        <HomePageCategoryTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                targetUrl={data.targetUrl}
                                imageClassKey={data.imageClassKey}
                                usePjax={this.props.gridConfig.usePjax}
                                onClickFn={this.props.onClickFn}
                                categoryName={data.category}
                                actorsCount={data.categoryAllActorsCount}
                        />
                );
                break;

            case Tile.type.CATEGORY_SPECIAL_TILE:
                tile = (
                        <CategorySpecialTile
                                key={uniqueKey}
                                uriParam={data.uriParam}
                                content={data.content}
                                backgroundClass={data.backgroundClass}
                                iconClass={data.iconClass}
                                isMobile={this.props.isMobile}
                                targetUrl={data.targetUrl}
                                useRatio={true}
                                isActive={data.isActive}
                                categoryOverviewLink={data.categoryOverviewLink}
                                usePjax={this.props.gridConfig.usePjax}
                        >
                            {badges} {plate}
                        </CategorySpecialTile>
                );
                break;

            case Tile.type.COVER_TILE:
                tile = (
                        <CoverTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                item={data}
                                previewPictureUrls={data.previewPictureUrls}
                                useRatio={true}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                isMobile={this.props.isMobile}
                                onClickFn={this.props.onClickFn}
                                tileIndex={this.props.tileIndex}
                                usePjax={this.props.gridConfig.usePjax}
                        >
                            {this.props.showBadges && badges} {plate}
                        </CoverTile>
                );
                break;

            case Tile.type.COVER_TILE_LIGHT:
                tile = (
                        <CoverTileLight
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                item={data}
                                previewPictureUrls={data.previewPictureUrls}
                                useRatio={true}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                isMobile={this.props.isMobile}
                                onClickFn={this.props.onClickFn}
                                tileIndex={this.props.tileIndex}
                                usePjax={this.props.gridConfig.usePjax}
                        >
                            {plate}
                        </CoverTileLight>
                );
                break;

            case Tile.type.MAGAZINE_GRID_TILE:
                tile = (
                        <MagazineTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                item={data}
                                previewPictureUrls={data.previewPictureUrls}
                                useRatio={true}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                isMobile={this.props.isMobile}
                                onClickFn={this.props.onClickFn}
                                usePjax={data.usePjax}
                                cols={data.cols}
                                targetUrl={data.targetUrl}
                                lazyImageLoading={this.props.lazyImageLoading}
                        >
                            {plate}
                        </MagazineTile>
                );
                break;

            case Tile.type.CONTEST_GRID_TILE:
                tile = (
                        <ContestTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                item={data}
                                isMobile={this.props.isMobile}
                                onClickFn={this.props.onClickFn}
                                tileIndex={this.props.tileIndex}
                                lazyImageLoading={this.props.lazyImageLoading}
                        >
                            {plate}
                        </ContestTile>
                );
                break;

            case Tile.type.LAST_TILE:
                tile = (<LastTile item={data} />);
                break;

            case Tile.type.VIDEO_STATION_TILE:
                tile = (<VideoStationTile actors={data.actors} videoStationLink={data.videoStationLink} className={this.props.gridTileClassName} />);
                break;

            case Tile.type.PHOTO_ALBUM_GRID_TILE:
            case Tile.type.VIDEO_ALBUM_GRID_TILE:
            case Tile.type.ACTOR_TOP100_GRID_TILE:
            case Tile.type.ACTOR_LOCATION_GRID_TILE:
            case Tile.type.ACTOR_TOP_FAVORITE_GRID_TILE:
            case Tile.type.ACTOR_BIRTHDAY_GRID_TILE:
            case Tile.type.ACTOR_TOP_RATED_CHAT_GRID_TILE:
            default:
                tile = (
                        <DefaultTile
                                key={uniqueKey}
                                className={this.props.gridTileClassName}
                                item={data}
                                useRatio={true}
                                contentBoxClassModifier={this.props.contentBoxClassModifier}
                                isMobile={this.props.isMobile}
                                onClickFn={this.props.onClickFn}
                                useTileWithSecondary={this.props.useTileWithSecondary}
                                tileIndex={this.props.tileIndex}
                                usePjax={this.props.gridConfig.usePjax}
                                ignorePjaxScroll={false}
                                showShadowGradient={data.showShadowGradient}
                                hasMultipleItems={this.props.hasMultipleItems}
                                lazyImageLoading={this.props.lazyImageLoading}
                                navigateTo={this.props.gridConfig.navigateTo}
                        >
                            {badges} {ribbons} {plate}
                        </DefaultTile>
                );
                break;
        }

        return !this.state.isHidden ? tile : null;
    }
}

Tile.propTypes = {
    animateTile:                       PropTypes.bool,
    autoAnimateInView:                 PropTypes.bool,
    badgeContainerAdditionalClassName: PropTypes.string,
    gridConfig:                        PropTypes.object.isRequired,
    gridMode:                          PropTypes.string,
    gridTileClassName:                 PropTypes.string,
    guestIsLoggedIn:                   PropTypes.bool.isRequired,
    isMobile:                          PropTypes.bool,
    onButtonClickFn:                   PropTypes.func,
    onClickFn:                         PropTypes.func,
    contentBoxClassModifier:           PropTypes.string,
    proSearchActiveCategory:           PropTypes.string,
    tileData:                          PropTypes.shape({
        age:                        PropTypes.number,
        actorId:                    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        id:                         PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isOnline:                   PropTypes.bool,
        isContestContent:           PropTypes.bool,
        isDildocontrolAvailable:    PropTypes.bool,
        isCamRecording:             PropTypes.bool,
        crossPriceBadge:            PropTypes.string,
        actorName:                  PropTypes.string,
        tileIdentifier:             PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isExclusiveContent:         PropTypes.bool,
        todayFreeShow:              PropTypes.bool,
        isTopAmateur:               PropTypes.bool,
        showFathersDayRibbon:       PropTypes.bool,
        todayFreeShowStart:         PropTypes.string,
        bannedCountryCodes:         PropTypes.array,
        targetUrl:                  PropTypes.string,
        type:                       PropTypes.string,
        isFilterResetAvailable:     PropTypes.bool,
        reward:                     PropTypes.object,
        rewardVideoUrl:             PropTypes.string,
        modalBackgroundUrl:         PropTypes.string,
        modalBackgroundTabletUrl:   PropTypes.string,
        qmDate:                     PropTypes.string,
        eventId:                    PropTypes.number,
        playlist:                   PropTypes.array,
        showContactLayer:           PropTypes.bool,
        showButtonMessenger:        PropTypes.bool,
        chatId:                     PropTypes.number,
        chatRating:                 PropTypes.number,
        profileLink:                PropTypes.string,
        previewPictureUrls:         PropTypes.array,
        multiChatPrice:             PropTypes.number,
        singleC2CChatPrice:         PropTypes.number,
        asFavoriteAdded:            PropTypes.string,
        showTextChatButtonOnly:     PropTypes.bool,
        actorAge:                   PropTypes.number,
        newElements:                PropTypes.number,
        showNewContentBanner:       PropTypes.bool,
        isModelGuestFavorite:       PropTypes.bool,
        messagePrice:               PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isEmailNotificationEnabled: PropTypes.bool,
        isSmsNotificationEnabled:   PropTypes.bool,
        lastOnlineDiffDays:         PropTypes.number,
        newElementTarget:           PropTypes.string,
        headline:                   PropTypes.string,
        subheadline:                PropTypes.string,
        description:                PropTypes.string,
        desktopLink:                PropTypes.string,
        status:                     PropTypes.string,
        tileImagePath:              PropTypes.string,
        badges:                     PropTypes.array,
        size:                       PropTypes.number,
        colorStyle:                 PropTypes.string,
        isShowPlateOnHover:         PropTypes.bool,
        imageClassKey:              PropTypes.string,
        actorsPreviewPictures:      PropTypes.array,
        languageCode:               PropTypes.string,
        category:                   PropTypes.string,
        categoryAllActorsCount:     PropTypes.number,
        isActorsInfoHidden:         PropTypes.bool,
        uriParam:                   PropTypes.string,
        content:                    PropTypes.string,
        backgroundClass:            PropTypes.string,
        iconClass:                  PropTypes.string,
        isActive:                   PropTypes.bool,
        categoryOverviewLink:       PropTypes.string,
        usePjax:                    PropTypes.bool,
        cols:                       PropTypes.number,
        actors:                     PropTypes.array,
        videoStationLink:           PropTypes.string,
        showShadowGradient:         PropTypes.bool,
        isCurrentlyTicketShow:      PropTypes.bool,
        guestHasTicket:             PropTypes.bool,
        isMessengerExcluded:        PropTypes.bool,
        isOnlineMobileVideocall:    PropTypes.bool,
    }).isRequired,
    tileIndex:                         PropTypes.number.isRequired,
    useTileWithSecondary:              PropTypes.bool,
    hasMultipleItems:                  PropTypes.bool,
    showBadges:                        PropTypes.bool,
    showPremiumBadge:                  PropTypes.bool,
    useThumbsVoting:                   PropTypes.bool,
    lazyImageLoading:                  PropTypes.bool,
    headlineType:                      PropTypes.oneOf(Object.values(Flux.Constants.Headlines)),
};

Tile.defaultProps = {
    animateTile:                       false,
    autoAnimateInView:                 false,
    badgeContainerAdditionalClassName: '',
    gridMode:                          Flux.Constants.TileGridModes.GRID,
    gridTileClassName:                 '',
    isMobile:                          false,
    useTileWithSecondary:              false,
    hasMultipleItems:                  false,
    showBadges:                        false,
    showPremiumBadge:                  false,
    useThumbsVoting:                   false,
    onClickFn:                         () => {
    },
    lazyImageLoading:                  false,
};

Tile.type = {
    ACTOR_GRID_TILE:                     'ACTOR_GRID_TILE',
    ACTOR_TEXT_TILE:                     'ACTOR_TEXT_TILE',
    CHAT_GRID_TILE:                      'CHAT_GRID_TILE',
    FAVORITE_GRID_TILE:                  'FAVORITE_GRID_TILE',
    FAVORITE_EXTENDED_GRID_TILE:         'FAVORITE_EXTENDED_GRID_TILE',
    HIGHLIGHT_ACTOR_GRID_TILE:           'HIGHLIGHT_ACTOR_GRID_TILE',
    HIGHLIGHT_TEASER_GRID_TILE:          'HIGHLIGHT_TEASER_GRID_TILE',
    PHOTO_ALBUM_GRID_TILE:               'PHOTO_ALBUM_GRID_TILE',
    PROSEARCH_BONUS_TILE:                'PROSEARCH_BONUS_TILE',
    PROSEARCH_SEPARATOR_GRID_TILE:       'PROSEARCH_SEPARATOR_GRID_TILE',
    PROSEARCH_NO_RESULTS_GRID_TILE:      'PROSEARCH_NO_RESULTS_GRID_TILE',
    PROSEARCH_WELCOME_CLIP_ROTATOR_TILE: 'PROSEARCH_WELCOME_CLIP_ROTATOR_TILE',
    PROSEARCH_MYSTERY_BOX_TILE:          'PROSEARCH_MYSTERY_BOX_TILE',
    VIDEO_ALBUM_GRID_TILE:               'VIDEO_ALBUM_GRID_TILE',
    CATEGORY_OVERVIEW_TILE:              'CATEGORY_OVERVIEW_TILE',
    NEW_CATEGORY_OVERVIEW_TILE:          'NEW_CATEGORY_OVERVIEW_TILE',
    CATEGORY_SPECIAL_TILE:               'CATEGORY_SPECIAL_TILE',
    ACTOR_TOP100_GRID_TILE:              'ACTOR_TOP100_GRID_TILE',
    ACTOR_LOCATION_GRID_TILE:            'ACTOR_LOCATION_GRID_TILE',
    ACTOR_TOP_FAVORITE_GRID_TILE:        'ACTOR_TOP_FAVORITE_GRID_TILE',
    ACTOR_BIRTHDAY_GRID_TILE:            'ACTOR_BIRTHDAY_GRID_TILE',
    ACTOR_TOP_RATED_CHAT_GRID_TILE:      'ACTOR_TOP_RATED_CHAT_GRID_TILE',
    CONTEST_GRID_TILE:                   'CONTEST_GRID_TILE',
    COVER_TILE:                          'COVER_TILE',
    COVER_TILE_LIGHT:                    'COVER_TILE_LIGHT',
    MAGAZINE_GRID_TILE:                  'MAGAZINE_GRID_TILE',
    LAST_TILE:                           'LAST_TILE',
    VIDEO_STATION_TILE:                  'VIDEO_STATION_TILE',
    NEWLY_REGISTERED_ACTOR_TILE:         'NEWLY_REGISTERED_ACTOR_TILE',
    VIDEO_ACTORS_TILE:                   'VIDEO_ACTORS_TILE',
    HOMEPAGE_CATEGORY_TILE:              'HOMEPAGE_CATEGORY_TILE',
    HOMEPAGE_ACTOR_TILE:                 'HOMEPAGE_ACTOR_TILE',
    HOMEPAGE_FAVORITE_ACTOR_TILE:        'HOMEPAGE_FAVORITE_ACTOR_TILE',
};

export default Tile;
