import React        from 'react';
import PropTypes    from 'prop-types';
import ReloadHelper from '../../utils/ReloadHelper';

class CategorySpecialTile extends React.Component {

	constructor(props) {
		super(props);

		this.createMarkup = this.createMarkup.bind(this);
		this.onClick      = this.onClick.bind(this);
	}

	createMarkup() {
		return {__html: this.props.content};
	}

	onClick(e) {
		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.targetUrl, e);
		}
	}

	render() {
		return (
			<div className={this.props.isMobile ? "col-xs-12" : "col-lg-3 col-xs-3"}>
				<a href={this.props.targetUrl} onClick={this.onClick}>
					<div className={"categories__tiles-image " + this.props.backgroundClass}>
						<div className={(this.props.isActive) ? "categories__tiles-background -active" : "categories__tiles-background"}>
							{!this.props.isMobile &&
								<div>
									<div className={"categories__tiles-icon " + this.props.iconClass}></div>
									<div className="categories__tiles-text" dangerouslySetInnerHTML={this.createMarkup()} />
								</div>
							}
							{this.props.children}
						</div>
					</div>
				</a>
			</div>
		);
	}
}

CategorySpecialTile.propTypes = {
	targetUrl:            PropTypes.string.isRequired,
	backgroundClass:      PropTypes.string.isRequired,
	iconClass:            PropTypes.string.isRequired,
	children:             PropTypes.node,
	content:              PropTypes.string.isRequired,
	isActive:             PropTypes.bool.isRequired,
	isMobile:             PropTypes.bool,
	categoryOverviewLink: PropTypes.string.isRequired,
	usePjax:              PropTypes.bool,
};


export default CategorySpecialTile;
