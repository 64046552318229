import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

class TileBadgeCamRecording extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}>{Translations.get('CamRecording')}</span>;
	}
}

TileBadgeCamRecording.propTypes = {
	show:      PropTypes.bool.isRequired,
	className: PropTypes.string,
};

TileBadgeCamRecording.defaultProps = {
	show:      false,
	className: "badge--normal tile-badge tile-badge-cam-recording",
};

export default TileBadgeCamRecording;
