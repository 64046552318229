import React        from 'react';
import PropTypes    from 'prop-types';
import ReloadHelper from '../../utils/ReloadHelper';

class NewCategoryTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		if (typeof this.props.onClickFn === 'function') {
			this.props.onClickFn(e, this.props.targetUrl);
		}

		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.targetUrl, e);
		}
	}

	render() {
		const categoryStyle = {height: '100%'};

		const pictureClass = "maincategories-picture " + this.props.imageClassKey;

		const tileContent = (
			<div className={this.props.className}>
				<a href={this.props.targetUrl} className={'category__overview-tilelink'} onClick={this.onClick}>
					<div className="context-box--media-box -full-border clearfix">
						<div className={"box--fixed-ratio -ratio1_1 " + pictureClass} style={categoryStyle}>
							{this.props.children}
						</div>
					</div>
				</a>
			</div>
		);

		return tileContent;
	}
}

NewCategoryTile.defaultProps = {
};

NewCategoryTile.propTypes = {
	children:      PropTypes.node,
	className:     PropTypes.string.isRequired,
	imageClassKey: PropTypes.string.isRequired,
	onClickFn:     PropTypes.func,
	targetUrl:     PropTypes.string.isRequired,
	usePjax:       PropTypes.bool,
};

export default NewCategoryTile;
