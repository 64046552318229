import PropTypes                   from 'prop-types';
import Flux                        from '../../flux/Flux';
import React                       from 'react';
import Translations                from '../../utils/Translations';
import {VXPay}                     from "../../utils/VXPay";
import VXButton                    from "../SimpleElements/VXButton/VXButton";
import {COLOR_TYPE_SPECIAL_INVERT} from "../SimpleElements/VXButton/ButtonHelper";
import {VXButtonLineConfig}              from "../SimpleElements/VXButton/VXButtonConfig";
import {formatCurrency, getStaticAmount} from '../../utils/CommonUtils.js';

class ProSearchBonusTile extends React.Component {

	static onRedeemClick() {
		VXPay.openPaytour({
			option: Flux.Constants.PromoCodes.BONUS_TILE,
		});
	}

	constructor(props) {
		super(props);
	}

	render() {
        let buttonClass         = '';
        let contentBoxClassName = 'content-box';
        const bonusAmount       = formatCurrency(getStaticAmount('promoUpToOnTopBonus'));

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		if (this.props.isInTeasers) {
			contentBoxClassName += ' h-no-padding';
		} else {
			contentBoxClassName += ' -padding-equal-smooth';
		}

		let inTeasersClassName = ' ';

		if (this.props.isInTeasers) {
			inTeasersClassName = ' -is-in-teasers';
			if (this.props.isLandscape) {
				inTeasersClassName += ' -landscape';
			}
		}
		if (!this.props.isMobile) {
			buttonClass = "-landing-page ";
		}


		return (
			<div className={this.props.className}
			     onClick={ProSearchBonusTile.onRedeemClick}
			>
				<div className={contentBoxClassName}>
					<figure className={"separator-tile -bonus-tile" + inTeasersClassName}>
						<div className={"separator-tile__content " + buttonClass + inTeasersClassName}>
							{Translations.get('TeaserBonusTileText1')}
							<span className={"separator-tile__teaser -bonus-tile" + buttonClass + inTeasersClassName}>
								{Translations.get('TeaserBonusTileText2') + ' + ' + Translations.get('TeaserBonusTileText3')}
								<div className={"separator-tile__teaser--subline " + buttonClass + inTeasersClassName}>
									{Translations.get('TeaserBonusTileText4Amount').replace(':amount', bonusAmount)}
								</div>
								{Translations.get('TeaserBonusTileText5')}
							</span>
							<VXButton
								color={COLOR_TYPE_SPECIAL_INVERT}
								line1={[new VXButtonLineConfig(Translations.get('RedeemVoucher'))]}
							/>
						</div>
					</figure>
				</div>
			</div>
		);
	}

}

ProSearchBonusTile.propTypes = {
	className:               PropTypes.string,
	contentBoxClassModifier: PropTypes.string,
	tileSubline:             PropTypes.string,
	isInTeasers:             PropTypes.bool,
	isLandscape:             PropTypes.bool,
	isMobile:                PropTypes.bool,
};

ProSearchBonusTile.defaultProps = {
	className:               '',
	contentBoxClassModifier: '',
	tileSubline:             '',
	isInTeasers:             false,
	isLandscape:             false,
	isMobile:                false,
};

export default ProSearchBonusTile;
