import React                  from 'react';
import PropTypes              from 'prop-types';
import Flux                   from '../../../flux/Flux';
import Translations           from './../../../utils/Translations';
import {VXPay, VXPayAction}   from '../../../utils/VXPay';
import PictureBoxes           from './../PictureBoxes';
import ActorContactContainer  from './ActorContactContainer';
import ActorPictureNoPreviews from './ActorPictureNoPreviews';
import DildocontrolOverlay    from "../DildocontrolOverlay";
import VisibilitySensor       from "react-visibility-sensor";

class NewlyRegisteredActorTile extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			isTileInView: false,
		};

		this.pictureBoxes = null;

		this.openMessengerFn  = this.openMessengerFn.bind(this);
		this.updateTileInView = this.updateTileInView.bind(this);
	}

	// this.props.item.type: Actor, FavoriteActor, GuestChat
	openMessengerFn(e) {
		if (Flux.Guest.isLoggedIn()) {
			Flux.Messenger.selectChannelByActorId(this.props.item.actorId, true);
		} else {
			const action = new VXPayAction(Flux.Constants.ActionTypes.Messenger.SELECT_CHANNEL, {
				targetId: this.props.item.actorId,
			});

			if (Flux.Guest.isAnonymousVoicecall()) {
				VXPay.openSignup({
					host:  this.props.item.actorId,
					texts: {
						BonusTextCustom: Translations.get('VoicecallVXPayTeasserText'),
					},
				}, action);
			} else {
				VXPay.openSignupOrLogin({
					host: this.props.item.actorId,
				}, action);
			}
		}
		if ((typeof e === 'object') && e.cancelable) {
			e.stopPropagation();
			e.preventDefault();
		}

		if (typeof this.props.onOpenMessengerClick === 'function') {
			this.props.onOpenMessengerClick();
		}

		return false;
	}

	updateTileInView(isVisible) {
		if (this.state.isTileInView !== isVisible) {
			this.setState({
				isTileInView: isVisible,
			});
		}
	}

	render() {
		const item                    = this.props.item;
		let additionalImageClassName  = '';
		let additionalFigureClassName = '';
		let contentBoxClassName       = 'content-box';

		// for mobile: only use the first image to improve performance
		if (this.props.isMobile && !this.props.autoAnimateInView && item.previewPictureUrls.length) {
			item.previewPictureUrls.length = 1;
		}

		if (this.props.contentBoxClassModifier) {
			contentBoxClassName += ' ' + this.props.contentBoxClassModifier;
		}

		if (this.props.animateTile === true && item.previewPictureUrls.length > 1) {
			additionalImageClassName = ' -animation-start';
		}

		if (this.state.isTileInView === true && item.previewPictureUrls.length > 1) {
			additionalImageClassName  = ' -animation-start';
			additionalFigureClassName = ' -show-badges';
		}

		if (item.previewPictureUrls.length <= 1) {
			additionalFigureClassName = ' -no-animation';
		}

		if (this.props.isTall && this.props.isMobile) {
			additionalFigureClassName += ' -height-tall';
		}

		const images = item.previewPictureUrls.length === 0
			? <ActorPictureNoPreviews />
			: (
				<PictureBoxes
					pictures={item.previewPictureUrls}
					useIndexForClassName={true}
					outerClassName={'actor-image' + additionalImageClassName}
					ref={(pictureBoxesRef) => {
						this.pictureBoxes = pictureBoxesRef;
					}}
					setMaxWidth={item.usesAvatarAsPreviewPicture}
					lazyImageLoading={this.props.lazyImageLoading}
				/>
			);

		let actorTile = <div className={this.props.className}>
			<div className={contentBoxClassName}>
				<a href={item.targetUrl}
				   className={'link -color-standard-white h-disp-block actor-tile' + additionalFigureClassName}
				   id={'actor-tile-' + this.props.tileIndex}
				>
					{images}
					{item.isDildocontrolAvailable && item.isOnline && <DildocontrolOverlay />}
					{this.props.isMobile && <div className="click-block-overlay" />}
					<ActorContactContainer
						show={this.props.showContactLayer}
						openMessenger={this.openMessengerFn}
						multiChatPrice={item.multiChatPrice}
                        singleC2CChatPrice={item.singleC2CChatPrice}
						actorId={item.actorId}
						isOnline={item.isOnline}
						isGuestLoggedIn={Flux.Guest.isLoggedIn()}
						hasLivePreview={item.isLivePreviewEnabled}
						sublineText={item.sublineText}
						isActorPinned={item.isModelGuestFavorite}
					/>

					{this.props.children}
				</a>
			</div>
		</div>;

		if (this.props.autoAnimateInView && item.previewPictureUrls.length > 1) {
			actorTile = <VisibilitySensor onChange={this.updateTileInView} intervalDelay={750} delayedCall={true}>
				{actorTile}
			</VisibilitySensor>;
		}

		return actorTile;
	}
}

NewlyRegisteredActorTile.propTypes = {
	isTall:                  PropTypes.bool,
	item:                    PropTypes.object.isRequired,
	children:                PropTypes.node,
	className:               PropTypes.string,
	tileIndex:               PropTypes.number.isRequired,
	animateTile:             PropTypes.bool,
	animateTouch:            PropTypes.bool,
	autoAnimateInView:       PropTypes.bool,
	showButtonProfile:       PropTypes.bool,
	showContactLayer:        PropTypes.bool,
	isHighlight:             PropTypes.bool,
	isMobile:                PropTypes.bool,
	onClickFn:               PropTypes.func,
	onOpenMessengerClick:    PropTypes.func,
	contentBoxClassModifier: PropTypes.string,
	usePjax:                 PropTypes.bool,
	lazyImageLoading:        PropTypes.bool,
};

NewlyRegisteredActorTile.defaultProps = {
	className:               'col-lg-3 col-xs-6',
	animateTile:             false,
	animateTouch:            false,
	autoAnimateInView:       false,
	contentBoxClassModifier: '-padding-equal-smooth',
	showContactLayer:        true,
	showButtonProfile:       false,
	isHighlight:             false,
	isMobile:                false,
	onClickFn:               () => {
	},      // params: e, actorData
	usePjax:                 false,
	lazyImageLoading:        false,
};

export default NewlyRegisteredActorTile;
