import React     from 'react';
import PropTypes from 'prop-types';
import '../../../src/VX/Asset/Less/VX/07-components/base/voting.less';

const DISLIKE = 1;
const LIKE    = 5;

class VotingThumbs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			rating: this.props.initialRating,
		};
	}

    UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.initialRating !== this.props.initialRating) {
			this.setState({
				rating: newProps.initialRating,
			});
		}
	}

	vote(index, e) {
		this.setState({
			rating:     index,
		});

		if (typeof this.props.onVoteClick === 'function') {
			this.props.onVoteClick(index, e);
		}
	}

	getContent() {
		switch (this.state.rating) {
			case LIKE:
				return <i className="icon -icon-like-full" />;
			case DISLIKE:
				return <i className="icon -icon-unlike-full" />;
			default:
				return [<i className="icon -icon-like-line voting-thumbs__like" onClick={(e) => this.vote(LIKE, e)} />, <i className="icon -icon-unlike-line voting-thumbs__unlike" onClick={(e) => this.vote(DISLIKE, e)} />];
		}
	}


	render() {
		const content = this.getContent();
		return (
			<div className="voting-thumbs">
				{content}
			</div>
		);
	}
}

VotingThumbs.propTypes = {
	initialRating:   PropTypes.number,
	onVoteClick:     PropTypes.func,
};

VotingThumbs.defaultProps = {
	initialRating:   0,
};

export default VotingThumbs;
