import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from './../../utils/Translations';

class CategoryOverviewPlate extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const actorsAllCount    =
			      <div>
				      <span className="icon -icon-girl-full" />
				      <span className="h-vertical-align-top">
					      {this.props.actorsAllCount} {Translations.get('NavbarLabelModels')}
					  </span>
			      </div>;
		const actorsOnlineCount =
			      <div>
				      <span className="icon -icon-webcam-single-full" />
				      <span className="h-vertical-align-top">
					      {this.props.actorsOnlineCount} {Translations.get('Online')}
					  </span>
			      </div>;
		return (
			<div className="row context-box--media-box__plate -category-plate-extended">
				<div className="context-box--media-box__maincategories-box-plate box--fixed-ratio__content h-text-align-center">
					<div className="h-disp-table-cell h-vertical-align-middle">
						<div className="context-box--media-box__maincategories-plate-categorie">{this.props.category}</div>
						<div>
							<div className="context-box--media-box__maincategories-plate-detail">
								{!this.props.isActorsInfoHidden && actorsAllCount}
							</div>
							<div className="context-box--media-box__maincategories-plate-detail">
								{!this.props.isActorsInfoHidden && actorsOnlineCount}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CategoryOverviewPlate.propTypes = {
	category:           PropTypes.string.isRequired,
	actorsOnlineCount:  PropTypes.number.isRequired,
	actorsAllCount:     PropTypes.number.isRequired,
	isActorsInfoHidden: PropTypes.bool,
};

export default CategoryOverviewPlate;

