import React        from 'react';
import PropTypes    from 'prop-types';

const CLASS_NAME = 'badge--highlight tile-badge tile-badge-fetish';

class TileBadgeFetish extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME}>{this.props.role}</span>;
	}
}

TileBadgeFetish.propTypes = {
	show: PropTypes.bool.isRequired,
	role: PropTypes.string,
};

TileBadgeFetish.defaultProps = {
	show: false,
};

export default TileBadgeFetish;
