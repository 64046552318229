import TileBadge18plus       from './TileBadge18plus';
import TileBadgePremium      from './TileBadgePremium';
import React                 from 'react';
import Tile                  from './../Tile';
import PropTypes             from 'prop-types';
import TileBadgeLive         from './TileBadgeLive';
import TileBadgeVideocall    from './TileBadgeVideocall';
import TileBadgeNew          from './TileBadgeNew';
import TileBadgeSpecial      from './TileBadgeSpecial';
import TileBadgeVip          from './TileBadgeVip';
import TileBadgeExclusive    from './TileBadgeExclusive';
import TileBadgeCrossPrice   from './TileBadgeCrossPrice';
import TileBadgeNeedsBuying  from './TileBadgeNeedsBuying';
import TileBadgeBought       from './TileBadgeBought';
import TileBadgeCamRecording from './TileBadgeCamRecording';
import TileBadgeGift         from './TileBadgeGift';
import TileBadgeFree         from './TileBadgeFree';
import TileBadgeFreeClip     from './TileBadgeFreeClip';
import TileBadgeContest      from './TileBadgeContest';
import TileBadgeStar         from './TileBadgeStar';
import TileBadgeHD           from './TileBadgeHD';
import TileBadgeFetish       from './TileBadgeFetish';
import TileBadgeLivePreview  from './TileBadgeLivePreview';
import Flux                  from '../../../flux/Flux';
import TileNewVideosCount    from "./TileNewVideosCount";
import TileBadgeTippingGoal  from "./TileBadgeTippingGoal";

class TileBadgesList extends React.Component {


	constructor(props) {
		super(props);

		this.state = {
			isOnline: null,
		};

		this.onActorDataChange = this.onActorDataChange.bind(this);
	}


	componentDidMount() {
		Flux.Favorite.addActorsChangeListener(this.onActorDataChange);
	}

	componentWillUnmount() {
		Flux.Favorite.addActorsChangeListener(this.onActorDataChange);
	}

	onActorDataChange() {
		if (this.props.data.type === 'HOMEPAGE_FAVORITE_ACTOR_TILE' || this.props.data.type === 'FAVORITE_EXTENDED_GRID_TILE') {
			const actors = Flux.Favorite.getActors();

			for (let i = 0; i < actors.length; i++) {
				if (actors[i].id === this.props.data.actorId) {
					this.setState({isOnline: actors[i].isOnline});
				}
			}
		}
	}


	render() {
		let badgesArr             = [];
		const data                = this.props.data;
		const loggedInOrVoiceCall = Flux.Guest.isLoggedIn() || Flux.Guest.isAnonymousVoicecall();
		const showFreeClipBadge   = loggedInOrVoiceCall
			&& this.props.isPricedContent
			&& data.price === 0
			&& !data.isGift
			&& data.type === Tile.type.VIDEO_ALBUM_GRID_TILE
			&& !data.isVipContent;

		const showFetish = data.hasOwnProperty('fetishRole')
			&& data.fetishRole.length > 0
			&& this.props.proSearchActiveCategory === 'fetish';

		const additionalClasses = this.props.additionalClass + (!data.isDildocontrolAvailable && data.isTopAmateur ? ' -is-top-amateur' : '');

		if (this.state.isOnline !== null) {
			data.isOnline = this.state.isOnline;
		}

		if (this.props.showOnlyFsk18Badge) {
			badgesArr = data.isOver18Content ? [<TileBadge18plus show={true} key={0} />] : [];
		} else {
			badgesArr = [
				<TileBadgeLive className={'badge--normal' + (!data.isDildocontrolAvailable ? ' -float-right' : '')} key={1} show={data.isOnline} />,
				<TileBadgeVideocall className={'badge--normal' + (!data.isDildocontrolAvailable ? ' -float-right' : '')} key={23} show={data.isOnlineMobileVideocall} />,
				<TileBadgeNew key={2} show={data.isNew} />,
				<TileBadgeSpecial key={3} content={data.specialBadge} />,
				<TileBadgeVip key={4} show={loggedInOrVoiceCall && data.isVipContent && !data.isGift} />,
				<TileBadgeExclusive key={5} show={data.isExclusiveContent} />,
				<TileBadge18plus key={6} show={data.isOver18Content} />,
				<TileBadgeCrossPrice
					key={7}
					show={Boolean(loggedInOrVoiceCall && data.price > 0 && data.needBuying && data.crossPriceBadge)}
					crossPriceBadge={data.crossPriceBadge}
				/>,
				<TileBadgeNeedsBuying
					key={8}
					show={this.props.showPrice && loggedInOrVoiceCall && data.price > 0 && data.needBuying && !data.crossPriceBadge}
					crossPriceBadge={data.crossPriceBadge}
					price={data.price}
				/>,
				<TileBadgeBought
					key={9}
					show={loggedInOrVoiceCall && data.price > 0 && !data.needBuying && !data.isGift}
				/>,
				<TileBadgeCamRecording
					key={10}
					show={data.isCamRecording}
				/>,
				<TileBadgeGift key={11} show={loggedInOrVoiceCall && !data.needBuying && data.isGift} />,
				<TileBadgeFree
					key={12}
					show={loggedInOrVoiceCall && this.props.isPricedContent && data.price === 0 && data.type === Tile.type.PHOTO_ALBUM_GRID_TILE}
				/>,
				<TileBadgeFreeClip key={13} show={showFreeClipBadge} />,
				<TileBadgeContest
					key={14}
					show={data.isContestContent && !data.isGift && data.type === Tile.type.VIDEO_ALBUM_GRID_TILE}
				/>,
				<TileBadgeStar key={15} show={data.isStar} />,
				<TileBadgeHD key={16} show={data.isChatQualityHD} />,
				<TileBadgeFetish key={18} show={showFetish} role={data.fetishRole} />,
				<TileBadgeLivePreview key={19} show={data.isLivePreviewEnabled} />,
				<TileNewVideosCount key={20} show={data.showNewVideosCount} newVideosCount={data.newVideosCount} />,
				<TileBadgePremium key={21} show={data.isOver18Content && this.props.showPremium} />,
				<TileBadgeTippingGoal key={22} show={data.isTippingGoal} />,
			];
		}

		if (badgesArr.length === 0) {
			return null;
		}

		return <div className={'badge-container' + additionalClasses}>{badgesArr}</div>;
	}
}

TileBadgesList.propTypes = {
	additionalClass:         PropTypes.string,
	proSearchActiveCategory: PropTypes.string,
	showOnlyFsk18Badge:      PropTypes.bool,
	isPricedContent:         PropTypes.bool,
	isMobile:                PropTypes.bool,
	showPremium:             PropTypes.bool,
	showPrice:               PropTypes.bool,
	data:                    PropTypes.shape({
		type:                    PropTypes.string,
		fetishRole:              PropTypes.string,
		isContestContent:        PropTypes.bool,
		isChatQualityHD:         PropTypes.bool,
		needBuying:              PropTypes.bool,
		specialBadge:            PropTypes.string,
		price:                   PropTypes.number,
		crossPriceBadge:         PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		isGift:                  PropTypes.bool,
		isVipContent:            PropTypes.bool,
		isNew:                   PropTypes.bool,
		isStar:                  PropTypes.bool,
		isOnline:                PropTypes.bool,
		isDildocontrolAvailable: PropTypes.bool,
		isTopAmateur:            PropTypes.bool,
		isOver18Content:         PropTypes.bool,
		isCamRecording:          PropTypes.bool,
		showNewVideosCount:      PropTypes.bool,
		actorId:                 PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		isExclusiveContent:      PropTypes.bool,
		isLivePreviewEnabled:    PropTypes.bool,
		newVideosCount:          PropTypes.number,
		isTippingGoal:           PropTypes.object,
		isOnlineMobileVideocall: PropTypes.bool,
	}).isRequired,
};

TileBadgesList.defaultProps = {
	additionalClass:    '',
	showOnlyFsk18Badge: false,
	isMobile:           false,
	isPricedContent:    false,
	showPremium:        true,
	showPrice:          true
};

export default TileBadgesList;
