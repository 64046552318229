import React     from 'react';
import PropTypes from 'prop-types';

class ActorInterviewPlate extends React.PureComponent {
	render() {
		return (
			<div className="context-box--media-box__plate row  clearfix">
				<div className="col-xs-12 h-height-full">
					<i className="context-box--media-box__icon icon -icon-play-line"/>
					<div className="context-box--media-box__title">{this.props.title}</div>
					<div className="context-box--media-box__subline">
						<span className="h-color-fg-description">{this.props.duration}</span> <span>{this.props.sublineText}</span>
					</div>
				</div>
			</div>
		);
	}
}


ActorInterviewPlate.propTypes = {
	id:                 PropTypes.number.isRequired,
	title:              PropTypes.string.isRequired,
	sublineText:        PropTypes.string.isRequired,
	duration:           PropTypes.string,
};

ActorInterviewPlate.defaultProps = {
	title:              '',
	sublineText:        '',
	duration:           0,
};

export default ActorInterviewPlate;