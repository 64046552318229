/*global VXConfig*/
import React from 'react';

class GermanOnlyBadge extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	render() {
		if (VXConfig.language.toUpperCase() === 'DE') {
			return null;
		}

		return <span className={"badge badge--german-only"}>GERMAN ONLY</span>;
	}

}

export default GermanOnlyBadge;