import Translations from '../../../utils/Translations';
import React        from 'react';
import PropTypes    from 'prop-types';

class TopAmateurRibbon extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<div className="ribbon--highlight -top-amateur">
				<span className="ribbon--highlight__star icon -icon-star-full" />
				{Translations.get('TopAmateur')}
				<span className="ribbon--highlight__star icon -icon-star-full" />
			</div>
		);
	}
}

TopAmateurRibbon.propTypes = {
	show: PropTypes.bool,
};

TopAmateurRibbon.defaultProps = {
	show: false,
};

export default TopAmateurRibbon;
