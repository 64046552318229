import React            from 'react';
import PropTypes        from 'prop-types';
import Translations     from '../../../utils/Translations';

const CLASS_NAME = 'badge--normal tile-badge tile-badge-free';

class TileBadgeFree extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME}>{Translations.get('Free')}</span>;
	}
}

TileBadgeFree.propTypes = {
	show: PropTypes.bool.isRequired,
};

TileBadgeFree.defaultProps = {
	show: false,
};

export default TileBadgeFree;
