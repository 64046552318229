/*globals VXConfig */

import Flux      from '../../../flux/Flux';
import React     from 'react';
import PropTypes from 'prop-types';

const LAYOUT_4X4 = '4x4';

class FathersDayRibbon extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		const small = this.props.pictureLayout === LAYOUT_4X4 && !this.props.useTileWithSecondary;
		const notDE = VXConfig.language !== Flux.Constants.Languages.DE;

		return (
			<div className={'ribbon--bubble -fathers-day' + (this.props.useTileWithSecondary ? ' -pictures-secondary' : '')}>
				<div className={'fathers-day__badge' + (small ? ' -small' : '') + (notDE ? ' -language-en' : '')} />
			</div>
		);
	}
}

FathersDayRibbon.propTypes = {
	show:                 PropTypes.bool,
	useTileWithSecondary: PropTypes.bool,
	pictureLayout:        PropTypes.string.isRequired,
};

FathersDayRibbon.defaultProps = {
	show:                 false,
	useTileWithSecondary: false,
};

export default FathersDayRibbon;