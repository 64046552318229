import React            from 'react';
import PropTypes        from 'prop-types';
import { getCommonTranslation } from '../../../utils/TranslationHelper';

class TileBadgeTippingGoal extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={this.props.className}>{getCommonTranslation('TippingGoal')}</span>;
	}
}

TileBadgeTippingGoal.propTypes = {
	show: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
		achieved: 		 PropTypes.bool,
		goal: 	  		 PropTypes.number,
		progressPercent: PropTypes.number,
		tipsSum: 		 PropTypes.number,
	})]),
	className: PropTypes.string,
};

TileBadgeTippingGoal.defaultProps = {
	show: false,
	className: "badge--highlight tile-badge tile-badge-tipping-goal",
};

export default TileBadgeTippingGoal;
