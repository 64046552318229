import React     from 'react';
import PropTypes from 'prop-types';
import ActorPin  from '../../components/Favorite/Actor/Pin';

class ChatPlate extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="context-box--media-box__plate h-clear show-on-Tablet">
				<div className="context-box--media-box__custombox -detailsbox  h-left">
					{this.props.isOnline &&
					<div className="context-box--media-box__custombox -status h-left">
						<span className="badge--online" />
					</div>
					}

					<div className="context-box--media-box__custombox -actorelements h-left">
						{this.props.actorName} ({this.props.actorAge})
					</div>
					<div className={(this.props.isOnline) ? "context-box--media-box__custombox -description h-clear" : "context-box--media-box__custombox -description -no-indent h-clear"}>
						<span className="icon -icon-round-time-line" /> {this.props.chatDurationString}, {this.props.chatDateString}
					</div>
				</div>
				<div className="context-box--media-box__custombox -heart h-right">
					<ActorPin actorId={this.props.actorId}
					          initialIsFavoriteOfGuest={this.props.isModelGuestChatFavorite}
					          fontSize="-size-xs"
					/>
				</div>
			</div>
		);
	}
}

ChatPlate.propTypes = {
	isOnline:                 PropTypes.bool.isRequired,
	actorId:                  PropTypes.number.isRequired,
	actorName:                PropTypes.string.isRequired,
	actorAge:                 PropTypes.number.isRequired,
	isModelGuestChatFavorite: PropTypes.bool.isRequired,
	chatDurationString:       PropTypes.string.isRequired,
	chatDateString:           PropTypes.string.isRequired,
};

export default ChatPlate;
