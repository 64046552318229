import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

class ActorTop100Plate extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="context-box--media-box__plate h-clear">
				<div className="context-box--media-box__custombox -top3box">
					{this.props.actorPosition <= 3 &&
					<div className="h-height-full">
						<i className={"icon-actor -icon-award-user-ranking-" + this.props.actorPosition + " -size-md h-left"} />
						<div className="h-disp-block h-text-align-center h-vertical-transform-middle">
							<h3>{this.props.actorName}</h3> <span className="context-box--media-panel__actor--age">({this.props.actorAge})</span>
						</div>
					</div>
					}
					{this.props.actorPosition > 3 &&
					<div className="h-height-full">
						<div className="h-flex -direction-column -align-center">
							<div className="h-flex">
								<h3>{this.props.actorName}</h3> 
								<span className="context-box--media-panel__actor--age h-ml-5">({this.props.actorAge})</span>
							</div>
							<div className="context-box--media-box__custombox -description h-left">
								{Translations.get('Ranking')} {this.props.actorPosition}
							</div>
						</div>
					</div>
					}
				</div>
			</div>
		);
	}
}

ActorTop100Plate.propTypes = {
	actorName:     PropTypes.string.isRequired,
	actorAge:      PropTypes.number.isRequired,
	actorPosition: PropTypes.number.isRequired,
};

export default ActorTop100Plate;
