import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';

class ActorLocationPlate extends React.PureComponent {
	render() {
		return (
			<div className="context-box--media-box__plate h-clear">
				<div className="context-box--media-box__custombox -top3box">
					<div className="h-height-full">
						<div className="context-box--media-box__custombox -actorelements h-left">
							{this.props.actorName} <span className="context-box--media-panel__actor--age">({this.props.actorAge})</span>
						</div>
						<div className="context-box--media-box__custombox -description h-left">
							<span className="icon -icon-geolocation-full" /> {this.props.distance} {Translations.get('AwayFromYou')}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ActorLocationPlate.propTypes = {
	actorName:     PropTypes.string.isRequired,
	actorAge:      PropTypes.number.isRequired,
	distance:      PropTypes.number.isRequired,
	actorLocation: PropTypes.string,
};

export default ActorLocationPlate;