import React        from 'react';
import PropTypes    from 'prop-types';
import ReloadHelper from '../../utils/ReloadHelper';
import Translations from '../../utils/Translations';

class HomePageCategoryTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		if (typeof this.props.onClickFn === 'function') {
			this.props.onClickFn(e, this.props.targetUrl);
		}

		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.targetUrl, e);
		}
	}

	render() {
		const pictureClass = 'maincategories-picture ' + this.props.imageClassKey;

		return (
			<div className={this.props.className}>
				<a href={this.props.targetUrl} className={'category__overview-tilelink'} onClick={this.onClick}>
					<div className="context-box--media-box -full-border clearfix">
						<div className={'box--fixed-ratio -ratio1_1 ' + pictureClass}>
							<div className="context-box--media-box__homemaincategories">
								<div className="context-box--media-box__homemaincategories-title">{this.props.categoryName}</div>
								<div className="context-box--media-box__homemaincategories-count">{this.props.actorsCount} {Translations.get('HomePageCategoriesCams')}</div>
							</div>
						</div>
					</div>
				</a>
			</div>
		);
	}
}

HomePageCategoryTile.defaultProps = {};

HomePageCategoryTile.propTypes = {
	children:      PropTypes.node,
	className:     PropTypes.string.isRequired,
	imageClassKey: PropTypes.string.isRequired,
	onClickFn:     PropTypes.func,
	targetUrl:     PropTypes.string.isRequired,
	usePjax:       PropTypes.bool,
	actorsCount:   PropTypes.number.isRequired,
	categoryName:  PropTypes.string.isRequired,
};

export default HomePageCategoryTile;
