import React        from 'react';
import PropTypes    from 'prop-types';

const CLASS_NAME = 'badge--highlight tile-badge tile-badge-special';

class TileBadgeSpecial extends React.PureComponent {
	render() {
		if (!this.props.content) {
			return null;
		}

		return <span className={CLASS_NAME}>{this.props.content}</span>;
	}
}

TileBadgeSpecial.propTypes = {
	content: PropTypes.any,
};

export default TileBadgeSpecial;
