import React            from 'react';
import PropTypes        from 'prop-types';
import {formatCurrency} from '../../../utils/CommonUtils';

class TileBadgeNeedsBuying extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		const classNames = this.props.crossPriceBadge ? ['badge--highlight'] : ['badge--normal'];

		classNames.push('tile-badge');
		classNames.push('tile-badge-needs-buying');

		return (
			<span className={classNames.join(' ')}>{formatCurrency(this.props.price)}</span>
		);
	}
}

TileBadgeNeedsBuying.propTypes = {
	show:            PropTypes.bool.isRequired,
	crossPriceBadge: PropTypes.any,
	price:           PropTypes.number,
};

TileBadgeNeedsBuying.defaultProps = {
	show: false,
};

export default TileBadgeNeedsBuying;
