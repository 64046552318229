import React                  from 'react';
import PropTypes              from 'prop-types';
import Translations           from '../../utils/Translations';
import {getCommonTranslation} from '../../utils/TranslationHelper';

class ActorTopFavoritePlate extends React.PureComponent {

	render() {
		return (
			<div className="context-box--media-box__plate h-clear">
				<div className="context-box--media-box__custombox -top3box">
					{this.props.actorPosition <= 3 &&
					<div className="h-height-full">
						<i className={"icon-actor -icon-award-place-" + this.props.actorPosition + " -size-md h-left"} />
						<div className="h-flex -direction-column -align-center">
							<div className='h-flex'>
								<h3>{this.props.actorName}</h3> 
								<span className="context-box--media-panel__actor--age h-ml-5">({this.props.actorAge})</span>
							</div>
							<div className="context-box--media-box__custombox -description"><i className="icon -icon-heart-full" /> {this.props.pinnedCount} {getCommonTranslation('added')} ({getCommonTranslation('LastXDays', 7)})</div>
						</div>
					</div>
					}
					{this.props.actorPosition > 3 &&
					<div className="h-height-full">
						<div className="h-flex -direction-column -align-center">
							<div className="h-flex">
								<h3>{this.props.actorName}</h3> 
								<span className="context-box--media-panel__actor--age h-ml-5">({this.props.actorAge})</span>
							</div>
							<div className="context-box--media-box__custombox -description h-left">
								<i className="icon -icon-heart-full" /> {this.props.pinnedCount} {getCommonTranslation('added')}, {Translations.get('Ranking')} {this.props.actorPosition} ({getCommonTranslation('LastXDays', 7)})
							</div>
						</div>
					</div>
					}
				</div>
			</div>
		);
	}
}

ActorTopFavoritePlate.propTypes = {
	actorName:     PropTypes.string.isRequired,
	actorAge:      PropTypes.number.isRequired,
	actorPosition: PropTypes.number.isRequired,
	pinnedCount:   PropTypes.number.isRequired,
};

export default ActorTopFavoritePlate;
