import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../utils/Translations';
import ReloadHelper from '../../utils/ReloadHelper';

class LastTile extends React.Component {

	constructor(props) {
		super(props);

		this.onClickFn = this.onClickFn.bind(this);
	}


	onClickFn(e) {
		ReloadHelper.reload(this.props.item.targetUrl, e);
	}

	render() {
		return <div className="context-box--media-box h-over-hidden clearfix -no-padding -no-margin" style={{
			backgroundImage:  'url(' + this.props.item.actorPreviewPicture + ')',
			backgroundRepeat: 'no-repeat',
			backgroundSize:   'cover',
		}}
		       >
			<div className="context-box--media-box__picture -background-opacity box--fixed-ratio -ratio4_3">
				<div className="box--fixed-ratio__content">
					<div className="h-disp-table h-height-full h-width-full">
						<div className="h-disp-table-cell h-vertical-align-middle">
							<div className="col-xs-12 actor-content-placeholder h-text-align-center clearfix">
								<span className="actor-content-placeholder__text--video"
								      dangerouslySetInnerHTML={{__html: Translations.get('VideoGridShowAllVideosFrom').replace(':actorName', this.props.item.actorName)}}
								/>
							</div>
							<div className="col-xs-12 actor-content-placeholder__button h-text-align-center clearfix">
								<a href="#"
								   className="btn -btn-color-special h-text-transform-none -click-material"
								   onClick={this.onClickFn}
								>
									{Translations.get('AllVideosActorVideos')}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}

LastTile.propTypes = {
	isMobile: PropTypes.bool,
	item:     PropTypes.object,
};

LastTile.defaultProps = {
	isMobile: false,
};

export default LastTile;
