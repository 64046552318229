import React     from 'react';
import PropTypes from 'prop-types';
import ActorPin  from '../../components/Favorite/Actor/Pin';

class FavoritePlate extends React.PureComponent {
	render() {
		return (
			<div className="context-box--media-box__plate">
				<ul className="h-list--horizontal">
					<li className="context-box--media-box__list__item actor-name">{this.props.actorName}</li>
					<li className="context-box--media-box__list__item actor-age">({this.props.actorAge})</li>
					<li className="context-box--media-box__list__item -right">
						<ActorPin actorId={this.props.actorId}
						          initialIsFavoriteOfGuest={this.props.isModelGuestChatFavorite}
						          fontSize="-size-xs"
						/>
					</li>
				</ul>
			</div>
		);
	}
}

FavoritePlate.propTypes = {
	actorId:                  PropTypes.number.isRequired,
	actorName:                PropTypes.string.isRequired,
	actorAge:                 PropTypes.number.isRequired,
	isModelGuestChatFavorite: PropTypes.bool.isRequired,
};

export default FavoritePlate;