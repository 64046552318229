import React from 'react';
import PropTypes from 'prop-types';

class CoverPlateLight extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="context-box--media-box__plate -light -height-sm h-text-align-center">
				<div className="content-box__cover-tile__title">{this.props.title}</div>
				<div className="content-box__cover-tile__subtitle"
				     dangerouslySetInnerHTML={{__html: this.props.sublineText ? this.props.sublineText : '&nbsp;'}}
				/>
			</div>
		);
	}
}

CoverPlateLight.propTypes = {
	title:       PropTypes.string,
	sublineText: PropTypes.string,
};

export default CoverPlateLight;