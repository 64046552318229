import Translations   from '../../../utils/Translations';
import {formatDateHM} from '../../../utils/CommonUtils';
import React          from 'react';
import PropTypes      from 'prop-types';

class FreeShowRibbon extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<div className="ribbon--bubble -free-show">
				<div className="show-bubble">
					<div className="h-vertical-align-middle">
						<h5 className="show-bubble__title">{Translations.get('FreeLiveShow')}</h5>
						<p className="show-bubble__text">{formatDateHM(new Date(this.props.showStart * 1000))}</p>
					</div>
				</div>
			</div>
		);
	}
}

FreeShowRibbon.propTypes = {
	show:      PropTypes.bool,
	showStart: PropTypes.number.isRequired,
};

FreeShowRibbon.defaultProps = {
	show: false,
};

export default FreeShowRibbon;
