import React from 'react';
import PropTypes from 'prop-types';


const HIGHLIGHT_TEASER_GRID_TILE = 'HIGHLIGHT_TEASER_GRID_TILE';

class CoverPlate extends React.Component {

	constructor(props) {
		super(props);

		this.onClickFn = this.onClickFn.bind(this);
	}

	onClickFn(e) {
		if (typeof this.props === 'function') {
			this.props.onClickFn(e, this.props.item);
		}
	}

	render() {
		const item       = this.props.item;
		const showDetail = item.showDetail;
		const index      = this.props.totalCount && item.showIndex ? ' (' + (this.props.totalCount - this.props.tileIndex) + ')' : '';

		let content;
		const addDescriptionClass = item.mediaType !== 'post' ? ' -margin-bottom' : '';

		const detailContent = <div>
			<div className={'context-box--media-box__title -margin-bottom h-float-none' + (this.props.iconClass ? '' : ' h-width-full')}
			     dangerouslySetInnerHTML={{__html: item.title + index}}
			/>
			<div className="h-clear">
				{item.description &&
				<div className={"context-box--media-box__subline--description h-text-normal" + addDescriptionClass}
				     dangerouslySetInnerHTML={{__html: item.description ? item.description : ''}}
				/>}
				<div className={"context-box--media-box__subline--description -text-ellipsis -margin-bottom h-text-normal"}>
					<span dangerouslySetInnerHTML={{__html: item.sublineText ? item.sublineText : ''}} />&nbsp;
					<span className="h-color-highlight-dark" dangerouslySetInnerHTML={{__html: item.highlightText ? item.highlightText : '&nbsp;'}} />
				</div>
			</div>
		</div>;

		const targetButton = <div className="h-clear">
			{item.targetUrl && item.targetText && <div className="h-text-align-center">
				<a href={item.targetUrl}
				   onClick={this.onClickFn}
				   className="tv-btn--c2a btn -btn-color-special -click-material h-text-align-center h-width-full"
				>
					{item.targetText}
				</a>
			</div>}
		</div>;

		// detail with an icon
		if (showDetail && this.props.iconClass) {
			content = <div>
				<div className="context-box--media-box__plate__col-left">
					{this.props.iconClass && <i className={'context-box--media-box__icon icon -size-sm h-float-none ' + this.props.iconClass} />}
				</div>
				<div className="context-box--media-box__plate__col-right">
					{detailContent}
				</div>
				{targetButton}
			</div>;
		}
		// detail without an icon
		else if (showDetail) {
			content = <div>
				{detailContent}
				{targetButton}
			</div>;
		}
		else {
			const titleClass       = item.type === HIGHLIGHT_TEASER_GRID_TILE ? ' -font-size-lg -no-margin-top' : ' -font-small';
			const sublineTextClass = item.type === HIGHLIGHT_TEASER_GRID_TILE ? ' -font-size-lg h-color-fg-description h-text-underline h-text-light' : ' h-text-normal';

			content = <div>
				<div className={"context-box--media-box__title" + titleClass} dangerouslySetInnerHTML={{__html: item.title}} />
				<div className={"context-box--media-box__subline--description" + sublineTextClass}>{item.sublineText}</div>
				<div className="context-box--media-box__subline h-color-highlight-dark -font-small -text-ellipsis h-text-normal"
				     dangerouslySetInnerHTML={{__html: item.highlightText ? item.highlightText : '&nbsp;'}}
				/>
			</div>;
		}
		const plateClass = item.type === HIGHLIGHT_TEASER_GRID_TILE ? ' -alpha' : '';

		return (
			<div className={'context-box--media-box__plate row clearfix' + plateClass + (showDetail ? ' -auto-height' : ' -extended-plate-no-icon')}>
				<div className={'col-xs-12 h-height-full' + (showDetail ? '' : ' h-text-align-center')}>
					{content}
				</div>
			</div>
		);
	}

}

CoverPlate.propTypes = {
	iconClass:  PropTypes.string,
	item:       PropTypes.object,
	onClickFn:  PropTypes.func,
	tileIndex:  PropTypes.number,
	totalCount: PropTypes.number,
};

export default CoverPlate;
