import React           from 'react';
import PropTypes       from 'prop-types';
import PictureBoxes    from './PictureBoxes';
import Translations    from '../../utils/Translations';
import ReloadHelper    from '../../utils/ReloadHelper';

const BIG = 'big';
const TODAYDATE = new Date;
const NOW = TODAYDATE.getTime();

class ContestTile extends React.Component {

	constructor(props) {
		super(props);
		this.onClickFn = this.onClickFn.bind(this);
		this.onClickToggleFlip = this.onClickToggleFlip.bind(this);
		this.state = {
			flipped: false,
		};
	}

	onClickFn(e) {
		this.props.onClickFn(e, this.props.item, this.props.tileIndex);
		if (!this.props.isMobile && this.props.item.targetUrl) {
			ReloadHelper.reloadWithPjax(this.props.item.targetUrl, e);
		}
	}

	onClickToggleFlip() {
		const currentState = this.state.flipped;
		this.setState({
			flipped: !currentState,
			fadeIn:  !this.state.fadeIn,
		});
	}

	render() {
		const tileClassName       = this.props.className;
		const item                = this.props.item;
		const contentBoxClassName = 'context-box--media-box ' + ((!this.props.isMobile) ? '-margin-size-lg ' : '') + 'h-over-hidden clearfix';
		const ratioClass          = ' box--fixed-ratio' + ((item.contestSize === BIG && !this.props.isMobile) ? ' -ratio100_125' : ' -ratio10_7');
		const outerClassName      = 'context-box--media-box__picture context-box--media-box__picture--border-bottom';
		const innerClassName      = 'box--fixed-ratio__content';
		const pictureBoxes        =
			      <PictureBoxes
				      nextContestPreview={item.contestPreviewLarge}
				      isNextContest={(item.contestEndDateTimestamp * 1000) > NOW}
				      isLastContest={item.isLatestContest}
				      lastContestImg={item.contestPreviewMedium}
				      pictures={item.previewPictureUrls}
				      useIndexForKey={true}
				      outerClassName={outerClassName + (!this.props.isMobile ? ratioClass : ' mobile-last-contest')}
				      innerClassName={!this.props.isMobile ? innerClassName : ''}
				      setMaxWidth={(item.contestSize === BIG && !this.props.isMobile) ? false : true}
				      isActiveContest={item.isActiveContest}
				      contestMediaType={item.contestMediaType}
				      contestStartDate={item.contestStartDate}
					  lazyImageLoading={this.props.lazyImageLoading}
			      />;
		const startBadge = (item.contestSize === BIG && !item.isActiveContest) ? <span className="context-box--media-box__contest--begin">{Translations.get('ContestsOff')} {item.contestStartDate}</span> : '';
		let tileContent;
		if (item.targetUrl) {
			tileContent = (
				<div className={contentBoxClassName}>
					<a
						className="js-pjax-link"
						href={item.targetUrl}
						onClick={this.onClickFn}
					>
						{!this.props.isMobile && startBadge}
						{pictureBoxes}
						{/* plates */}
						{this.props.children}
					</a>
				</div>
			);
		} else {
			tileContent = (
				<div className="contest-tile-flip-outer" onClick={this.onClickToggleFlip}>
					<div className={contentBoxClassName + (this.state.flipped ? " contest-tile-flip-inner flipped" : " contest-tile-flip-inner")}>
						<div className="contest-tile-flip-front">
							{!this.props.isMobile && startBadge}
							{pictureBoxes}
						</div>
						{!this.props.isMobile ? <div className="contest-tile-flip-back">
							<i className={("icon" + ((item.contestMediaType === 'video') ? ' -icon-video-clip' : ' -icon-smartphone-rings'))}/>
							<h4 className="contest-tile-flip-back_subheading">{Translations.get('Contest')}</h4>
							<h2 className="contest-tile-flip-back_heading">{item.contestTitle}</h2>
							<p className="contest-tile-flip-back_description">{item.contestDescription}</p>
							<p className="contest-tile-flip-back_period"><span className="red">Coming Soon: </span>{item.contestStartDate} - {item.contestEndDate}</p>
						</div> : <div className="contest-tile-flip-back">
							<p className="contest-tile-flip-back_period"><span className="red">Coming Soon: </span>{item.contestStartDate}</p>
							<h2 className="contest-tile-flip-back_heading">{item.contestTitle}</h2>
							<p className="contest-tile-flip-back_description">{item.contestDescription}</p>
						</div>}

						{/* plates */}
						{this.props.children}
					</div>
				</div>
			);
		}

		return (
			<div className={tileClassName}>
				{tileContent}
			</div>
		);
	}
}

ContestTile.propTypes = {
	children:         PropTypes.node,
	className:        PropTypes.string,
	isMobile:         PropTypes.bool,
	item:             PropTypes.object.isRequired,
	onClickFn:        PropTypes.func,
	tileIndex:        PropTypes.number,
	lazyImageLoading: PropTypes.bool,
};

ContestTile.defaultProps = {
	isMobile:         false,
	lazyImageLoading: false,
};

export default ContestTile;