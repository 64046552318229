import React     from 'react';
import PropTypes from 'prop-types';

const CLASS_NAME = 'badge--normal h-text-highlight tile-badge tile-badge-cross-price';

class TileBadgeCrossPrice extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<span className={CLASS_NAME}
			      dangerouslySetInnerHTML={{__html: this.props.crossPriceBadge}}
			/>
		);
	}
}

TileBadgeCrossPrice.propTypes = {
	show:            PropTypes.bool.isRequired,
	crossPriceBadge: PropTypes.any,
};

TileBadgeCrossPrice.defaultProps = {
	show: false,
};

export default TileBadgeCrossPrice;
