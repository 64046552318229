import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from './../../utils/Translations';

const VIDEO = 'video';

class ContestPlate extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="context-box--media-box__plate -contests-size-small">
				<div className="context-box--media-box__contest--icon">
					<i className={("icon" + ((this.props.contestMediaType === VIDEO) ? ' -icon-video-clip' : ' -icon-smartphone-rings'))} />
				</div>
				<div className="context-box--media-box__contest--infobox">
					<span className="context-box--media-box__contest--title">{this.props.contestTitle}</span>
				</div>
				<div className="context-box--media-box__contest--more">{Translations.get('Winner')}
					<i className={"icon -icon-double-arrow-right-line"} /></div>
			</div>
		);
	}
}

ContestPlate.propTypes = {
	contestTitle:     PropTypes.string.isRequired,
	contestMediaType: PropTypes.string,
};

export default ContestPlate;