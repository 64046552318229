import React     from 'react';
import PropTypes from 'prop-types';

class ActorBirthdayPlateLight extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="context-box--media-box__plate -light">
				<ul className="h-list--horizontal">
					<li className="context-box--media-box__list__item h-text-align-center h-width-full h-over-ellipsis">
						<span className="actor-name -size-sm h-text-normal">{this.props.name}</span>
						<span className="actor-age -smooth -size-sm h-text-normal">({this.props.age})</span>
					</li>
				</ul>
				<div className="context-box--media-box__custombox -birthday">
					<span className="icon-actor -icon-fact-birthday" /> {this.props.birthdayText}
				</div>
			</div>
		);
	}
}

ActorBirthdayPlateLight.propTypes = {
	name:         PropTypes.string.isRequired,
	age:          PropTypes.number.isRequired,
	birthdayText: PropTypes.string.isRequired,
};

export default ActorBirthdayPlateLight;
