import React        from 'react';
import PropTypes    from 'prop-types';
import PictureBoxes from '../TileGrid/PictureBoxes';
import ReloadHelper from '../../utils/ReloadHelper';

class CoverTileLight extends React.Component {

	constructor(props) {
		super(props);

		// bind
		this.onClickFn = this.onClickFn.bind(this);
	}

	onClickFn(e) {
		this.props.onClickFn(e, this.props.item, this.props.tileIndex);
		if (this.props.usePjax) {
			ReloadHelper.reloadWithPjax(this.props.item.targetUrl, e);
		}
	}

	render() {
		return (
			<div className={this.props.className} onClick={this.onClickFn}>
				<div className="content-box -padding-equal-smoother">
					<div className="content-box__cover-tile">
						<PictureBoxes pictures={this.props.previewPictureUrls}
						              useIndexForClassName={true}
						              outerClassName={'actor-image'}
						/>
					</div>
					{/* plates */}
					{this.props.children}
				</div>
			</div>
		);
	}

}

CoverTileLight.propTypes = {
	children:           PropTypes.node,
	className:          PropTypes.string,
	item:               PropTypes.object,
	onClickFn:          PropTypes.func,
	previewPictureUrls: PropTypes.array,
	tileIndex:          PropTypes.number,
	usePjax:            PropTypes.bool,
};

CoverTileLight.defaultProps = {
	className: 'col-xs-6',
	usePjax:   false,
};

export default CoverTileLight;
