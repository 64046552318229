import React from 'react';
import PropTypes from 'prop-types';
import Translations from '../../utils/Translations';


class MagazinePlate extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className="magazinehighlight__shadowbox">
				<div className={("magazinehighlight__textelements" + (!this.props.showButton ? ' -full-size' : ''))}>
					<div className="magazinehighlight__title">{this.props.title}</div>
					<div className="magazinehighlight__subtitle">{this.props.teaserText}</div>
					<div className="magazinehighlight__excerpt">{this.props.descText}</div>
				</div>
				{this.props.showButton &&
				<div className="magazinehighlight__ca2">
					{(this.props.isMobile) ?
						<button className="btn -btn-color-special">{Translations.get('MagazineReadNowOnline')}</button> :
						<button className="btn--icon -btn-color-special -icon-double-arrow-right-line -click-material h-text-uppercase">{Translations.get('MagazineReadNowOnline')}</button>
					}
				</div>
				}
			</div>
		);
	}

}

MagazinePlate.propTypes = {
	title:      PropTypes.string,
	teaserText: PropTypes.string,
	descText:   PropTypes.string,
	showButton: PropTypes.bool,
	isMobile:   PropTypes.bool,
};

export default MagazinePlate;
